import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Divider,
  Button,
  Box,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import RelateIcon from "../../../assets/Icons/RelateIcon.png";
import AppBar from "../../../components/AppBar/index";
import { updateSetting } from "../../../actions/setting";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import MobileAppbar from "../../../components/More/settings/AppBar";
import { useIntl } from "react-intl";
import axios from 'axios'

function PrivacyPolicy(props) {
  let dispatch = useDispatch();
  const classes = useStyles();
  let authReducerState = useSelector((state) => state.auth);
  let [privacy, setPrivacy] = useState('');
  let updatePrivacy = useSelector((state) => state.message);
  const intl = useIntl();

  useEffect(() => {
    if (updatePrivacy.show === true && updatePrivacy.severity === "success") {
      setTimeout(props.handleBack(null), 2000);
    }
  }, [updatePrivacy]);

  const getData =async () => {
    try {
      const res = await axios.get('https://landingadmin.omidlife.com/privacy')
      setPrivacy(res?.data?.privacy)
    } catch (err) {
      console.log('error', err);
    }
  }

  useEffect(() => {
    getData()

  }, [])
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const handleClick = (privacy) => {
    dispatch(
      updateSetting({
        token: authReducerState.token,
        setting: { privacy: privacy },
      })
    );
  };
  return (
    <Grid container lg={11} md={11} sm={11} xs={12} xl={11}>
      <Grid container>
        {isMobile && !isDesktop ? (
          <MobileAppbar
            body={intl.formatMessage({ id: "privacyPolicy" })}
            onClick={props.handleBack}
            backAction={"Settings"}
            backgroundColor="#87AC26"
          />
        ) : (
            <AppBar
              appBarConfig={{
                show: true,
                text: intl.formatMessage({ id: "privacyPolicy" }),
                backAction: props.handleBack,
              }}
            />
          )}
      </Grid>
      {/* <Grid
        container
        style={{ marginLeft: "25px", borderBottom: 1 }}
        spacing={2}>
        <Grid item xs={3} md={2} lg={2} sm={3} xl={1}>
          <img src={RelateIcon} alt="logo" className={classes.logoImage} />
        </Grid>

        <Grid
          item
          xs={9}
          md={8}
          lg={8}
          sm={8}
          xl={8}
          alignItems="center"
          alignContent="center"
          className={classes.fullHeight}
        >
          <Grid container direction="row">
            <Grid item xs={10}>
              <Typography className={classes.title}>
                {intl.formatMessage({ id: "termOfService" })}
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography className={[classes.subTitle]}>
                {intl.formatMessage({ id: "lastUpdated" })}
                {" 21 APR 2020"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={11} sm={7} md={6} lg={6} xl={6}>
          <Divider />
        </Grid>
      </Grid> */}
      <Grid
        container
        style={{
          overflow: "hidden",
          marginLeft: "25px",
          marginBottom: "5px",
          marginTop: "45px",
        }}>
        {/* <Grid item xs={11}>
          <Typography className={classes.header}>
            {"1. "}
            {intl.formatMessage({ id: "terms" })}
          </Typography>
        </Grid> */}
        <Grid item xs={11}>
          <Typography className={classes.body}>
            {
              privacy
            }
          </Typography>
        </Grid>
      </Grid>

      {/* <Grid
        container
        style={{
          overflow: "hidden",
          marginLeft: "25px",
          marginBottom: "5px",
          marginTop: "35px",
        }}>
        <Grid item xs={11}>
          <Typography className={classes.header}>
            {"2. "}
            {intl.formatMessage({ id: "Lisence" })}
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <Typography className={classes.body}>
            {
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean in venenatis ipsum, dictum venenatis nunc. Donec nec libero pellentesque tellus feugiat finibus at ac turpis. Suspendisse imperdiet ante a luctus tincidunt. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Suspendisse in mauris et enim tincidunt dignissim. Fusce Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean in venenatis ipsum, dictum venenatis nunc. Donec nec libero pellentesque tellus feugiat finibus at ac turpis. Suspendisse imperdiet ante a luctus tincidunt. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae;"
            }
          </Typography>
        </Grid>
      </Grid> */}

      <Grid
        container
        spacing={3}
        style={{
          overflow: "hidden",
          marginLeft: "25px",
          marginBottom: "30px",
          marginTop: "5px",
        }}>
        <Grid item>
          <Button
            variant="outlined"
            size={"large"}
            onClick={() => handleClick("Declined")}
            className={classes.unSelectBtn}
          >
            <Typography className={classes.btnText}>
              {intl.formatMessage({ id: "decline" })}
            </Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            size={"large"}
            onClick={() => handleClick("Accepted")}
            className={classes.selectedBtn}
          >
            <Typography className={classes.btnText}>
              {intl.formatMessage({ id: "accept" })}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PrivacyPolicy;

const useStyles = makeStyles((theme) => ({
  logoImage: {
    marginLeft: "10px",
    marginTop: "10px",
    height: "75px",
    width: "75px",
  },
  title: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: "26px",
    color: "#000000",
  },
  fullHeight: {
    height: "100%",
    width: "70%",
    display: "flex",
  },
  body: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "26px",
    letterSpacing: "0.035em",
    color: "#9E9393",
  },
  subTitle: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#8B8B8B",
  },
  header: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: "26px",
    color: "#000000",
  },
  selectedBtn: {
    background: "linear-gradient(#87AC26 1%,#87AC26 100%)",
    borderRadius: "11px",
    color: "#FFFFFF",
    padding: "10px",
    height: "50px",
    width: "140px",
  },
  unSelectBtn: {
    borderColor: "#87AC26",
    borderRadius: "11px",
    color: "#87AC26",
    padding: "10px",
    height: "50px",
    width: "140px",
  },
  btnText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
  },
}));
