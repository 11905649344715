import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import SocialLoginContainer from "../../../components/Auth/SocialLogin";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Signup from "../../../components/Auth/Signup";
import Login from "../../../components/Auth/Login";
import Ellipse from "../../../assets/Ellipse3.svg";
import Ellipse4 from "../../../assets/Ellipse4.svg";
import { useLocation } from "react-router-dom";
import RelateIcon from "../../../assets/Icons/RelateIcon.png";

import "./auth.scss";
import { useIntl } from "react-intl";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export default function AuthTabs() {
  const classes = useStyles();
  const intl = useIntl();
  let location = useLocation();
  const [value, setValue] = React.useState(
    location && location.id
      ? location.id
      : new URLSearchParams(location.search).get("tab") === "signup"
        ? 1
        : 0
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const setSignIn = () => {
    setValue(0);
  };

  return (
    <Grid container>
      <Grid item container xs={4}>
        <div
          className={classes.bottomLeft}
          style={{ backgroundColor: value === 1 ? "#d39898" : "#a6eae9" }}
        />
      </Grid>

      <Grid
        item
        container
        xs={4}
        style={{ height: "100vh", alignItems: "center" }}
      >
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <a href={process.env.REACT_APP_LANDING_URL}>
            <img src={RelateIcon} alt="logo" style={{ marginTop: "30px" }} />
          </a>
        </Grid>

        <Grid item xs={12} style={{ textAlign: "center" }}>
          <div
            className={classes.root}
            style={{ backgroundColor: "#FAFAFA", margin: "auto" }}
          >
            <AppBar
              position="static"
              color="default"
              style={{
                boxShadow: "none",
                backgroundColor: "white",
                marginTop: "30px"
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
                className={value === 0 ? "tab" : "tab1"}
              >
                <Tab
                  style={{
                    color: "#000"
                  }}
                  label={
                    <Typography variant="h5" className={classes.tabTitle}>
                      {intl.formatMessage({ id: "sign_in" })}
                    </Typography>
                  }
                  className={classes.tabColor}
                />
                <Tab
                  style={{
                    color: "#000"
                  }}
                  label={
                    <Typography variant="h5" className={classes.tabTitle}>
                      {intl.formatMessage({ id: "sign_up" })}
                    </Typography>
                  }
                  className={classes.tabColor}
                />
              </Tabs>
            </AppBar>
            <>
              <TabPanel value={value} index={0} className={classes.tabPanel}>
                <Login />
                <SocialLoginContainer />
              </TabPanel>
              <TabPanel value={value} index={1} className={classes.tabPanel}>
                <Signup setSignIn={setSignIn} />
                <SocialLoginContainer />
              </TabPanel>
            </>
          </div>
        </Grid>
      </Grid>
      <Grid item container xs={4} justify="flex-end">
        <Grid item xs={12} style={{ flexBasis: 0 }}>
          <img
            src={value === 0 ? Ellipse : Ellipse4}
            className={classes.topRight}
            alt=""
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#FFFFF !important",
    width: 380
  },
  tabTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "32px",
    lineHeight: "44px"
  },
  topRight: {
    width: "150px",
    height: "150px"
  },
  tabPanel: {
    backgroundColor: "white"
  },
  bottomLeft: {
    width: "50px",
    height: "50px",
    borderRadius: "100%",
    position: "fixed",
    bottom: 30,
    marginLeft: "50px"
  },
  fbBtn: {
    width: "164px",
    height: "69px",
    background: "#0656B4",
    borderRadius: "59px",
    "&:hover": {
      background: "#0656B4"
    }
  },
  tabColor: {
    color: "#000 !important"
  },
  gBtn: {
    width: "164px",
    height: "69px",
    background: "#FFFFFF",
    borderRadius: "59px",
    "&:hover": {
      background: "#ffff"
    }
  }
}));
