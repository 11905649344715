import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SocialLoginButton from "../../Common/SocialLoginButton";
import Grid from "@material-ui/core/Grid";
import { socialLoginUser } from "../../../actions/auth";

import { Button, Fab } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import fbIcon from "../../../assets/Icons/fbIcon.png";
import GIcon from "../../../assets/Icons/google-icon.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
const SocialLoginContainer = (props) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  let dispatch = useDispatch();
  const authReducerState = useSelector((state) => state.auth);

  const handleSocialLogin = (user) => {
    if (authReducerState.role) {
      dispatch(
        socialLoginUser({
          access_token: user._token.accessToken,
          role: authReducerState.role,
          url: "facebook",
        })
      );
    } else {
      dispatch(
        socialLoginUser({
          access_token: user._token.accessToken,
          role: "Regular",
          url: "facebook",
        })
      );
    }
  };

  const handleSocialLoginFailure = (err) => {
    console.error('fb err', err);
    if (err === 'User already connected') {
      dispatch({
        type: 'SHOW_MESSAGE',
        payload: {
          title: `Failed`,
          message:
            err
              ? err
              : ` failed to sign in`,
          severity: "error",
        },
      });
    }

  };

  const handleSocialLoginGoogle = (user) => {
    if (authReducerState.role) {
      dispatch(
        socialLoginUser({
          access_token: user._token.accessToken,
          role: authReducerState.role,
          url: "google",
        })
      );
    } else {
      dispatch(
        socialLoginUser({
          access_token: user._token.accessToken,
          role: "Regular",
          url: "google",
        })
      );
    }
  };

  const handleSocialLoginFailureGoogle = (err) => {
    console.error('google error', err);
    if (err === 'User already connected') {
      dispatch({
        type: 'SHOW_MESSAGE',
        payload: {
          title: `Failed`,
          message:
            err
              ? err
              : ` failed to sign in`,
          severity: "error",
        },
      });
    }
  };
  return (
    <>
      {isDesktop && (
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <SocialLoginButton
              disabled={authReducerState.loader}
              variant="contained"
              className={classes.fbBtn}
              provider="facebook"
              appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
              onLoginSuccess={handleSocialLogin}
              onLoginFailure={handleSocialLoginFailure}
              component={Button}
            >
              <img src={fbIcon} alt="f" />
            </SocialLoginButton>
          </Grid>
          <Grid item xs={6}>
            <SocialLoginButton
              disabled={authReducerState.loader}
              variant="contained"
              className={classes.gBtn}
              provider="google"
              appId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              onLoginSuccess={handleSocialLoginGoogle}
              onLoginFailure={handleSocialLoginFailureGoogle}
              component={Button}
            >
              <img src={GIcon} alt="g" />
            </SocialLoginButton>
          </Grid>
        </Grid>
      )}
      {isMobile && !isDesktop && (
        <Grid
          container
          item
          xs={10}
          justify="center"
          spacing={1}
          style={{ marginTop: "-90px" }}
        >
          <Grid item xs={3} justify="center">
            <SocialLoginButton
              disabled={authReducerState.loader}
              variant="contained"
              className={classes.fbBtnMobile}
              provider="facebook"
              key={"facebook"}
              appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
              onLoginSuccess={handleSocialLogin}
              onLoginFailure={handleSocialLoginFailure}
              component={Fab}
              size="large"
              color="primary"
            >
              <img
                src={fbIcon}
                alt="f"
                style={{ width: "15px", height: "25px" }}
              />
            </SocialLoginButton>
          </Grid>
          <Grid item xs={3} justify="center">
            <SocialLoginButton
              disabled={authReducerState.loader}
              variant="contained"
              size="large"
              color="primary"
              className={classes.gBtnMobile}
              provider="google"
              appId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              onLoginSuccess={handleSocialLoginGoogle}
              onLoginFailure={handleSocialLoginFailureGoogle}
              component={Fab}
            >
              <img
                src={GIcon}
                alt="google"
                style={{ width: "25px", height: "25px" }}
              />
            </SocialLoginButton>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SocialLoginContainer;

const useStyles = makeStyles((theme) => ({
  fbBtn: {
    width: "164px",
    height: "64px",
    background: "#0656B4",
    borderRadius: "59px",
    "&:hover": {
      background: "#0656B4",
    },
  },

  gBtn: {
    width: "164px",
    height: "64px",
    background: "#FFFFFF",
    borderRadius: "59px",
    "&:hover": {
      background: "#ffff",
    },
  },
  fbBtnMobile: {
    background: "linear-gradient(209.7deg, #0075FF 4.58%, #0B3A70 88.99%);",
    boxShadow: "0px 3px 6px rgba(18, 166, 166, 0.25)",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    letterSpacing: "0.09em",
    color: "#FFFFFF",
    textTransform: "none",
  },

  gBtnMobile: {
    background: "linear-gradient(210.07deg, #FFFFFF 13.46%, #FFFFFF 76.64%);",
    boxShadow: "0px 3px 6px rgba(18, 166, 166, 0.25)",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 200,
    fontSize: "18px",
    letterSpacing: "0.09em",
    color: "#FFFFFF",
    textTransform: "none",
  },
}));
