import { useContext, useEffect } from "react";
import ConfigContext from "../../../providers/Config/Context";
import { useSelector } from "react-redux";
import { Route, Redirect, useLocation, useHistory } from "react-router-dom";

function PublicRoute({ component: Component, redirectTo = "/auth", ...rest }) {
  const { appConfig } = useContext(ConfigContext);
  const { auth } = appConfig || {};
  const { state } = useLocation();
  const history = useHistory();
  const { isAuthenticated = () => false } = auth || {};
  const authReducerState = useSelector((state) => state.auth);
  useEffect(() => {
    if (
      authReducerState.isAuthenticated &&
      authReducerState?.user?.id &&
      (!authReducerState?.user?.pictureUrl || !authReducerState?.user?.coverUrl)
    ) {
      history.push(
        state?.from &&
          state?.from !== "/" &&
          state.from !== "" &&
          state.from !== "/auth"
          ? state.from
          : // : "/edit-profile-info"
            "/dashboard"
      );
    } else if (
      authReducerState.isAuthenticated &&
      !localStorage.getItem("subscriptionPageVisited")
    ) {
      localStorage.setItem("subscriptionPageVisited", "true");
      history.push(
        state?.from &&
          state?.from !== "/" &&
          state.from !== "" &&
          state.from !== "/auth"
          ? state.from
          : "/more/subscription-plan"
      );
    } else if (authReducerState.isAuthenticated) {
      history.push(
        state?.from &&
          state?.from !== "/" &&
          state.from !== "" &&
          state.from !== "/auth"
          ? state.from
          : authReducerState?.user?.role === "Regular"
          ? "/explore"
          : "/dashboard"
      );
    }
  }, [authReducerState]);
  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated(appConfig) && props.location.pathname !== "/" ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: redirectTo,
              state: {
                from:
                  props.location.pathname === "/" ? "/auth" : props.location,
              },
            }}
          />
        )
      }
    />
  );
}

export default PublicRoute;
