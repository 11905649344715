import { useEffect, useState } from 'react';
import MaterialTable from "material-table";
import { Grid, CircularProgress, useMediaQuery, Typography, Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { subscriptionLogs, getUserJoinedUnderSubscription } from '../../../actions/SubscriptionPlan';
import { useHistory } from 'react-router-dom';
import { GET_ALL_USER_JOINED_UNDER_SUBSCRIPTION } from '../../../actions/types'
import LeaveOrContinue from '../SubscriptionPlan/UnderSubscriptionUpdatePopup'
import SubscriptionStuff from './subscriptionStuff'
import moment from 'moment';



function SubscriptionPlan({data}) {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const auth = useSelector(state => state?.auth);
    const user = useSelector(state => state?.user?.user);
    const configs = useSelector(state => state?.setting?.siteConfig);
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [historyLoader, setHistoryLoader] = useState(false);
    const subscription = user?.purchasedSubscription && user?.purchasedSubscription[0]
    const allJoined = useSelector(state => state?.subscriptionPlan?.userUnderSubscriptionJoined);

    const[totalCourses ,setTotalCourses] = useState();
    const[totalSessions , setTotalSessions] = useState();
    const[totalRetreats, setTotalRetreats] =useState();
    const[totalTeacherTrainings ,setTeacherTrainings] = useState();

    const[totalAvailableCourses ,setTotalAvailableCourses] = useState();
    const[totalAvailableSessions , setTotalAvailableSessions] = useState();
    const[totalAvailableRetreats, setTotalAvailableRetreats] =useState();
    const[totalAvailableTeacherTrainings ,setAvailableTeacherTrainings] = useState();

    const getData = async () => {
        setLoading(true);
        setHistoryLoader(true);
        await dispatch(getUserJoinedUnderSubscription({ token: auth?.token }));
        let res = await dispatch(subscriptionLogs({ token: auth?.token }));
        if (res) {
            let data = res?.map((item, ind) => ({
                serialNumber: ind + 1,
                name: item?.SubscriptionPlan?.name,
                price: item?.SubscriptionPlan?.price,
                interval: item?.interval,
                startDate: moment(item?.startDate).format('DD-MMM-YY'),
                endDate: moment(item?.endDate).format('DD-MMM-YY'),
                status: item?.status,
            }));
            setHistoryData(data)
            setLoading(false);
            setHistoryLoader(false);
        } else {
            setLoading(false);
            setHistoryLoader(false);
        }
    }

    useEffect(() => {
       
        setTotalCourses(parseInt(allJoined?.omidCourses) + parseInt(allJoined?.teacherCourses))
        setTotalSessions(parseInt(allJoined?.omidSessions) + parseInt(allJoined?.teacherSessions))
        setTotalRetreats(parseInt(allJoined?.omidRetreats) + parseInt(allJoined?.teacherRetreats))
        setTeacherTrainings(parseInt(allJoined?.omidTeacherTrainings) + parseInt(allJoined?.teachersTeacherTrainings))

        setTotalAvailableCourses(parseInt(subscription?.SubscriptionPlan?.availableCourses) + parseInt(subscription?.SubscriptionPlan?.availableTeacherCourses))
        setTotalAvailableSessions(parseInt(subscription?.SubscriptionPlan?.availableSessions) + parseInt(subscription?.SubscriptionPlan?.availableTeacherSessions))
        setTotalAvailableRetreats(parseInt(subscription?.SubscriptionPlan?.availableRetreats) + parseInt(subscription?.SubscriptionPlan?.availableTeacherRetreats))
        setAvailableTeacherTrainings(parseInt(subscription?.SubscriptionPlan?.availableTeacherTrainings) + parseInt(subscription?.SubscriptionPlan?.availableTeachersTeacherTrainings))
    }, [user])

    useEffect(() => {
        getData()
        return () => {
            dispatch({
                type: GET_ALL_USER_JOINED_UNDER_SUBSCRIPTION,
                userUnderSubscriptionJoined: {}
            })
        }
    }, []);
       
    return (
        <>

            <LeaveOrContinue />
            {loading ? <div className={classes.loader}><CircularProgress style={{ color: '#0EB2EF' }} /></div> : <Grid container className={classes.container} style={{ padding: isMobile && '20px 10px 70px 10px' }}>
                <Grid container item xs={12} spacing={1}>
                    <Grid item >
                        <ArrowBack
                            className={classes.backArrow}
                            onClick={() => history.goBack()}
                        />
                    </Grid>
                    <Grid item >
                        <Typography className={classes.heading}>Subscription</Typography>
                    </Grid>
                </Grid>

                <Grid item xs={12} className={classes.upgradeBtnContainer} style={{ width: isMobile && '100%', right: isMobile && 0, top: 40, zIndex: 1 }}>
                    <Button onClick={() => history.push('/more/subscription-plan')} className={classes.btn}>Upgrade Subscription</Button>
                </Grid>
          {user?.purchasedSubscription?.length ? 
          <div className = {classes.container}>
            <Grid container item xs={12} className={classes.subscriptionDetailContainer} style={{ marginTop: 50 }}>

<Grid item xs={6} sm={4} md={2} >
    <Typography className={classes.title}>
        Subscription Plan :
    </Typography>
</Grid>
<Grid item xs={6} sm={8} md={10} >
    <Typography className={classes.value}>
        {subscription?.SubscriptionPlan?.name}
    </Typography>
</Grid>
</Grid>

          <Grid container item xs={12} className={classes.subscriptionDetailContainer}>
<Grid item xs={4} sm={3} md={2} >
    <Typography className={classes.title}>
        Price({subscription?.interval}) :
    </Typography>
</Grid>
<Grid item xs={8} sm={9} md={10} >
    <Typography className={classes.value}>
        {configs?.currency} {subscription?.interval === 'monthly' ? subscription?.SubscriptionPlan?.discounted : subscription?.SubscriptionPlan?.annualDiscountedPrice}
    </Typography>
</Grid>
</Grid>
          <Grid container item xs={12} className={classes.subscriptionDetailContainer}>
<Grid item xs={4} sm={3} md={2} >
    <Typography className={classes.title}>
        Subscription Interval :
    </Typography>
</Grid>
<Grid item xs={8} sm={9} md={10} >
    <Typography className={classes.value}>
        {subscription?.interval}
    </Typography>
</Grid>
</Grid>

          <Grid container item xs={12} className={classes.subscriptionDetailContainer}>
<Grid item xs={4} sm={3} md={2} >
    <Typography className={classes.title}>
        Subscription Date :
    </Typography>
</Grid>
<Grid item xs={8} sm={9} md={10} >
    <Typography className={classes.value}>
        {moment(subscription && subscription?.startDate).format('DD-MMM-YY')} to {moment(subscription && subscription?.endDate).format('DD-MMM-YY')}
    </Typography>
</Grid>
</Grid>

          <Grid container item xs={12} className={classes.subscriptionDetailContainer}>
<SubscriptionStuff link={'/view-course/'} title={subscription?.SubscriptionPlan?.omidCoursesTitle} data={totalCourses} joined={totalCourses?.length ? totalCourses : "0"} available={totalAvailableCourses ? totalAvailableCourses : "0"} />
</Grid>
          <Grid container item xs={12} className={classes.subscriptionDetailContainer}>
<SubscriptionStuff link={'/more/session/details/'} title={subscription?.SubscriptionPlan?.omidSessionsTitle} data={totalSessions} joined={totalSessions?.length ? totalSessions: "0"} available={totalAvailableSessions ? totalAvailableSessions : "0"} />
</Grid>
          <Grid container item xs={12} className={classes.subscriptionDetailContainer}>
<SubscriptionStuff link={'/retreat-details/'} title={"Retreats"} data={totalRetreats} joined={totalRetreats?.length ? totalRetreats : "0"} available={totalAvailableRetreats? totalAvailableRetreats :"0"} />
</Grid>

         <Grid container item xs={12} className={classes.subscriptionDetailContainer}>
<SubscriptionStuff link={'/teacher-training-details/'} title={"Teacher Training"} data={totalTeacherTrainings} joined={totalTeacherTrainings?.length ? totalTeacherTrainings : "0"} available={totalAvailableTeacherTrainings? totalAvailableTeacherTrainings : "0"} />
</Grid> 
          </div>
          
          : <Grid container item xs={12} className={classes.noSubscriptionDetailContainer}>
           <p>You have no subscription plan please subscribe</p>
            </Grid>}    
        
        
                 

             
             
               
                

                <Grid item xs={12}>
                    <MaterialTable
                        columns={[
                            {
                                title: "Sr.",
                                field: "serialNumber",
                                width: 50
                            },

                            {
                                title: "Name",
                                field: "name",
                            },
                            {
                                title: "Start Date",
                                field: "startDate",
                            },
                            {
                                title: "End Date",
                                field: "endDate",
                            },

                            {
                                title: `Price (${configs?.currency})`,
                                field: "price",
                            },
                            {
                                title: `Interval`,
                                field: "interval",
                            },

                            {
                                title: "Status",
                                field: "status",
                            },

                        ]}
                        isLoading={historyLoader}
                        data={historyData}
                        options={{
                            rowStyle: {
                                backgroundColor: "#EEE"
                            },

                        }}
                        title="Subscription logs"
                        style={{
                            boxShadow: 'none'
                        }}
                    />
                </Grid>
            </Grid>}
        </>
    );
}

export default SubscriptionPlan;

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 25,
        position: 'relative',
        width: '100%'
    },
    root: {
        width: '100%',
    },
    loader: {
        height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'
    },
    subscriptionDetailContainer: {
        padding: 15
    },
    noSubscriptionDetailContainer: {
        padding: 50
    },
    upgradeBtnContainer: {
        padding: 15,
        justifyContent: 'center',
        position: 'fixed',
        width: '30%',
        right: '15%'
    },
    hideScrollBar: {
        paddingTop: '15px',
        width: '100%',
        flexWrap: "unset",
        overflowX: "auto",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    heading: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '115%',
        color: '#000',
    },
    title: {
        fontFamily: theme.fontFamily.Reef,
        fontStyle: "normal",
        fontWeight: 900
    },
    value: {
        fontFamily: theme.fontFamily.Reef,
        fontStyle: "normal",
        fontWeight: 900,
        fontSize: "16px",
        lineHeight: "27px",
        color: "#B0B0B0",
        letterSpacing: "0.015em"
    },
    btn: {
        background: '#0DBCC7',
        width: '100%',

        '&:hover': {
            background: '#0DBCC7',
        },
        color: '#fff',
        fontSize: 20
    },
    backArrow: {
        cursor: 'pointer'
    },

}));