import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

function SubscribeNowBtn(props) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  let reducerUser = useSelector((state) => state?.user?.user);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div className={classes.container}>
      <Button
        className={classes.btn}
        style={
          props?.noMargin
            ? { width: isMobile && !isDesktop ? "70px" : "200px" }
            : {
                width: isMobile && !isDesktop ? "70px" : "200px",
                marginRight: isMobile && !isDesktop ? "0px" : "0px",
              }
        }
        onClick={() => history.push("/more/subscription-plan")}
      >
        {isDesktop
          ? reducerUser?.purchasedSubscription &&
            reducerUser?.purchasedSubscription[0]?.SubscriptionPlan?.name ===
              "Free"
            ? "Subscribe Now"
            : "Upgrade Subscription"
          : reducerUser?.purchasedSubscription &&
            reducerUser?.purchasedSubscription[0]?.SubscriptionPlan?.name ===
              "Free"
          ? "Subscribe"
          : "Subscribe"}
      </Button>
    </div>
  );
}

export default SubscribeNowBtn;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    float: "right",
  },
  btn: {
    background: "#0EB2EF",
    width: "100%",
    color: "#fff",
    "&:hover": {
      background: "#0EB2EF",
    },
  },
}));
