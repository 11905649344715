import React, { useState } from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
  TextField,
  Button
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import { useIntl } from "react-intl";

import { AppBar, Toolbar, IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";
import SocialMediaShare from "./SocialMediaShare";
import InvitePeoples from './InviteFriends'
import { useSelector, useDispatch } from 'react-redux'
import { addFriendsInChallenge } from '../../../actions/challenges'

function ChallengeInvitations(props) {
  console.log(props);
  const classes = useStyles();
  const intl = useIntl();
  let history = useHistory();
  let params = useParams();
  let dispatch = useDispatch();

  const [urlText, seturlText] = useState(
    `${window.location.origin}/share/challenge/${params?.id}`
  );
  const [copySuccess, setCopySuccess] = useState("");
  const challengeSelectedPeoples = useSelector(
    (state) => state?.challenges?.challengeParticipants
  );
  const auth = useSelector(
    (state) => state?.auth
  );

  const inviteHandler = () => {
    let participantsToBeAdded = [];

    participantsToBeAdded = challengeSelectedPeoples?.length > 0 &&
      challengeSelectedPeoples?.map((friend) => {
        if (friend?.status === "sent" && !friend?.invited) {
          return friend?.id
        }
      }).filter(Boolean);

    if (participantsToBeAdded?.length > 0) {
      dispatch(addFriendsInChallenge({
        token: auth?.token, data: {
          challengeOrSessionId: params?.id,
          type: "Challenge",
          userIds: participantsToBeAdded
        },
      }));
    }

    history?.location?.state?.from === "detail"
      ? history.goBack()
      : history.push("/more/relate-with-friends");
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      style={{ backgroundColor: "#fbfaf9", padding: "5px", minHeight: "100vh", paddingBottom: '75px' }}
    >
      <Grid item xs={12}>
        <AppBar
          className={classes.appBar}
          style={{ backgroundColor: "rgb(251, 250, 249)" }}
        >
          <IconButton
            onClick={() => {
              history?.location?.state?.from === "detail"
                ? history.goBack()
                : history.push("/more/relate-with-friends");
            }}
            className={classes.backButton}
          >
            <ArrowBack />
          </IconButton>
          <Toolbar>
            <Typography className={classes.appBarText} variant="h6">
              {intl.formatMessage({ id: "back" })}
            </Typography>
          </Toolbar>
        </AppBar>
      </Grid>
      <Grid item xs={12} md={6}>
        <SocialMediaShare
          url={urlText}
          sumaryText={"Here is challenge link to Join"}
          title={"Hey there,  Check this out, I think you will love it"}
          tags={["omidlife.com", "challenge"]}
          header={intl.formatMessage({ id: "inviteChallengeText" })}
        />
      </Grid>
      <Grid item xs={12} container>

        <Grid item xs={12} sm={10} md={8} lg={6} style={{ paddingLeft:"15px", paddingRight: "15px" }}>
          <InvitePeoples invitationType={'challenge'} />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={10} md={8} lg={6} style={{ margin: "15px",paddingRight: !isMobile && "25px" }}>
        <Button
          onClick={inviteHandler}
          style={{
            width: isMobile ? "100%" : "100%",
            height: "62px",
            background:
              "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
            borderRadius: "11px",
            fontFamily: theme.fontFamily.Roboto,
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "20px",
            lineHeight: "23px",
            textTransform: "none",
            color: "#FFFFFF"
          }}
        >
          {intl.formatMessage({ id: "finish" })}
        </Button>
      </Grid>
    </Grid>
  );
}

export default ChallengeInvitations;

const useStyles = makeStyles(theme => ({
  appBar: {
    color: "#000",
    flexDirection: "row",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    right: "unset",
    position: "relative",
    paddingLeft: "15px",
    boxShadow: "none"
  },
  appBarMobile: {
    background: "#FFFFFF",
    color: "#000",
    flexDirection: "row",
    padding: theme.spacing(1.5),
    justifyContent: "space-between",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    right: "unset",
    position: "sticky"
  },
  appBarText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "20px",
    lineHeight: "23px"
  },
  appBarTextMobile: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "26px",
    lineHeight: "35px"
  },
  backButton: {
    background: "transparent",
    color: "#000",
    padding: "0px"
  },
  linkTextStyle: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#8B8B8B",
    display: "flex",
    justifyContent: "center",
    height: "40px"
  },
  header: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "23px",
    color: "#C2C2C2",
    marginTop: "20px",
    marginBottom: "25px",
    marginLeft: "20px"
  },
  rootTextField: {
    "& .MuiInputBase-root": {
      backgroundColor: "#E5E5E5",
      fontFamily: theme.fontFamily.Roboto,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "21px",
      padding: "10px",
      marginTop: "35px",
      marginBottom: "45px",
      height: "58px",
      width: "100%",
      borderRadius: "36px"
    },
    "&.MuiBadge-colorPrimary": {
      backgroundColor: "#E5E5E5"
    },
    "&.MuiInput-underline:before": {
      border: `0px solid #E5E5E5`
    },
    "& .mui-focused": { border: `0px solid #E5E5E5` },
    "& .MuiInput-underline:after": {
      border: `0px solid #E5E5E5`
    },
    "&.MuiInput-underline:hover:not(.Mui-disabled)::before": {
      border: `0px solid #E5E5E5`
    }
  }
}));
