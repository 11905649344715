import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  Typography,
  Avatar,
  CardHeader,
  CardContent,
  Chip,
  Grid,
  Button
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Rating from '@material-ui/lab/Rating';
import moment from 'moment';

const hexToRGB = (hex, alpha) => {
  var r = parseInt(hex && hex.slice(1, 3), 16),
    g = parseInt(hex && hex.slice(3, 5), 16),
    b = parseInt(hex && hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
};

function CourseInstructorIntro(props) {
  const classes = useStyles();
  const { instructor, tags, remarks, status } = props;
  const [expand, setExpand] = useState(false);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const intl = useIntl();
  const authReducerState = useSelector(state => state.auth);
  const authUser = useSelector(state => state.user?.user);
  let courseDetail = useSelector(state => state?.courses?.courseDetail);

  const expandDescription = () => {
    setExpand(!expand);
  };



  return (
    <>

      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label="user's pic" src={instructor?.pictureUrl}>
              {instructor?.name.charAt(0).toUpperCase()}
            </Avatar>
          }
          title={
            <Link
              to={`/view-profile/${instructor?.id}`}
              style={{ textDecoration: "none" }}
            >
              <Typography
                className={`${classes.instructorIntroText} ${classes.instructorIntroTextTitle}`}>
                {instructor?.name}
              </Typography>
            </Link>
          }
          subheader={
            <Typography
              className={classes.instructorIntroText}
              style={{ fontSize: "14px", lineHeight: "19px", color: "#B0B0B0" }}
            >
              {instructor?.title}
            </Typography>
          }
        />

        <CardContent>
          <Typography
            className={`${classes.instructorIntroText} ${classes.instructorIntroTextSubTitle}`}
            style={{ marginTop: "-40px", lineHeight: '23px' }}
          >
            {instructor?.description && <div dangerouslySetInnerHTML={{ __html: unescape(instructor?.description) }} />}
          </Typography>


          <Grid container justify="space-between">
            <Typography
              className={`${classes.instructorIntroText} ${classes.instructorIntroTextTitle}`}
            >
              {intl.formatMessage({ id: "courseDescription" })}
            </Typography>
            {authReducerState?.user?.id === instructor?.id && status && <Chip
              label={status}
              className={`${classes.instructorIntroText}  ${classes.courseTags}`}
              style={{
                fontSize: "14px",
                color: "#FFF",
                background: status === "Active" ? "#52b202" : status === "Incomplete" ? "#ff9100" : status === "Blocked" ? "#f44336" : "#000"
              }}
            />}
          </Grid>

          <Typography
            className={`${classes.instructorIntroText} ${classes.instructorIntroTextSubTitle}`}
          >
            {remarks && <div dangerouslySetInnerHTML={{ __html: unescape(remarks) }} />}

          </Typography>

          {tags?.length > 0 && (
            <Typography
              className={`${classes.instructorIntroText} ${classes.instructorIntroTextTitle}`}
            >
              {intl.formatMessage({ id: "courseTags" })}
            </Typography>
          )}
          <Grid
            className={`${classes.instructorIntroText} ${classes.instructorIntroTextSubTitle}`}
          >
            {tags?.map((tag, ind) => {
              return (
                <Chip
                  label={tag}
                  key={ind}
                  className={`${classes.instructorIntroText}  ${classes.courseTags}`}
                  style={{
                    fontSize: "14px",
                    color: "#FFF",
                    marginTop: '4px',
                    background: `linear-gradient(360deg, ${hexToRGB(
                      props?.category?.color1,
                      1
                    )} 30%, ${hexToRGB(props?.category?.color2, 1)} 90%)`
                  }}
                />
              );
            })}
          </Grid>



          <Grid container
            style={{ paddingTop: "15px" }}

          >
            <Grid
              item
              xs={12}
            >
              <Typography style={{ display: 'flex', alignItems: 'center' }}>
                <Rating
                  name="simple-controlled"
                  size={'small'}
                  value={courseDetail?.averageRating}
                  readOnly={true}
                  precision={0.5}
                  classes={{ sizeSmall: classes.ratingSize }}
                />
                <span style={{ fontSize: "14px", color: "#83CCF0", marginLeft: '13px' }}>
                  {courseDetail?.reviews_ratings?.length > 0
                    ? courseDetail?.reviews_ratings?.length + " "
                    : ""}
                  {
                    courseDetail?.reviews_ratings?.length < 1 ? (intl.formatMessage({ id: "noReview" })) :
                      courseDetail?.reviews_ratings?.length === 1 ? (intl.formatMessage({ id: "Review" })) :
                        (intl.formatMessage({ id: "Reviews" }))

                  }
                </span>
              </Typography>
            </Grid>



          </Grid>

        </CardContent>
      </Card>
    </>
  );
}

export default CourseInstructorIntro;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    borderRadius: "10px",
    boxShadow: "0px 16px 20px rgba(123, 0, 0, 0.05)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"

  },
  instructorIntroText: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900
  },
  instructorIntroTextTitle: {
    fontSize: "20px",
    lineHeight: "27px",
    color: "#1B1B1B"
  },
  instructorIntroTextSubTitle: {
    fontSize: "16px",
    lineHeight: "26px",
    color: "#B0B0B0",
    letterSpacing: "0.016em",
    padding: "10px 0"
  },
  courseTags: {
    background:
      "linear-gradient(180deg, rgba(123, 146, 166, 0.95) 0%, rgba(21, 57, 89, 0.95) 100%)",
    borderRadius: "60px",
    transform: "rotate(3600deg)",
    marginRight: "3px"
  },
  readMore: {
    fontSize: '12px',
    cursor: 'pointer',
    color: '#84CDF0',
    '&:hover': {
      textDecoration: 'underline',
      color: '#84CDF0',
    }
  },
  ratingSize: {
    fontSize: '15px'
  }
}));
