import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { ArrowForwardIos } from "@material-ui/icons";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  IconButton,
  CircularProgress,
  useMediaQuery,
  Fab,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  InputLabel,
  Grid,
  Typography,
  Modal,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { loginUser } from "../../../actions/auth";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import Recaptcha from "react-google-invisible-recaptcha";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      width: "100%",
      margin: "11px 8px 11px 8px",
    },
  },
  privacy: {
    fontFamily: theme.fontFamily.Roboto,
    color: "#999387",
    fontSize: "14px",
  },
  signUpBtn: {
    background: "linear-gradient(186.43deg, #15AEBF 13.46%, #14D9D9 76.64%)",
    boxShadow: "0px 3px 6px rgba(18, 166, 166, 0.25)",
    borderRadius: " 104px",
    width: "355px",
    height: "64px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "28px",
    letterSpacing: "0.09em",
    color: "#FFFFFF",
    textTransform: "none",
  },
  signInBtnIcon: {
    background: "linear-gradient(210.1deg, #15AEBF 13.46%, #14D9D9 76.64%);",
    boxShadow: "0px 3px 6px rgba(18, 166, 166, 0.25)",
    width: "initial",
    height: "initial",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "28px",
    letterSpacing: "0.09em",
    transform: "rotate(75deg)",
    padding: "1rem",
    color: "#FFFFFF",
    textTransform: "none",
  },
  forwardIcon: {
    transform: "rotate(-75deg)",
  },
  paper: {
    position: "absolute",
    height: 330,
    maxWidth: 315,
    minWidth: 300,
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    outline: "none",
  },
  msgBody: {
    minHeight: "240px",
    alignItems: "center",
    display: "flex",
  },
  modelBodyText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "17px",
    lineHeight: "27px",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: "#192247",
  },
  modelBtn: {
    height: "73px",
    width: "100%",
    fontFamily: theme.fontFamily.Roboto,
    cursor: "pointer",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    color: theme.colors.white,
  },

  modalBtnGrid: {
    padding: "0px",
    alignSelf: "flex-end",
    color: "#fff",
    cursor: "pointer",
    background: `linear-gradient(#09A9BB 1%,#09A9BB 100%)`,
    height: "73px",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
}));

const Signup = (props) => {
  const classes = useStyles();
  let history = useHistory();
  const refCaptcha = React.useRef(null);
  const dispatch = useDispatch();
  const authReducerState = useSelector((state) => state.auth);
  const {setValue,  register, errors, handleSubmit, control, reset, watch } = useForm();
  const theme = useTheme();
  const intl = useIntl();
  const [open, setOpen] = React.useState(false);
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [dataObject, setDataObject] = React.useState(null);
  const [values, setValues] = React.useState({
    name: "",
    username: "",
    email: "",
    password: "",
    showPassword: false,
    showConfirmPassword: false,
    role: authReducerState.role ? authReducerState.role : "",
  });
  const [roleError, setRoleError] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);

  function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
      borderColor: theme.colors.white,
      borderRadius: "4px",
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top - 4}%, -${left}%)`,
    };
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const onSubmit = async (data) => {
    try {
      if (values?.role === "") {
        setRoleError(true);
      } else {
        await refCaptcha?.current?.callbacks?.execute();
        let token = await refCaptcha.current.callbacks.getResponse();
        if (token) {
          setDataObject({ ...data, role: values?.role, url: "signup" });
          const response = await dispatch(
            loginUser({ ...data, role: values?.role, url: "signup", recaptcha_token:token })
          );
          if (response && response.status && response.message) {
            console.log("message",response)
            setOpen(response.message);
          }
        } else {
          refCaptcha.current.callbacks.reset();
        }
      }
    } catch (e) {
      refCaptcha.current.callbacks.reset();
      console.log("e", e?.message);
    }
  };

  useEffect(() => {
    if (authReducerState.role) {
      reset({ ...values, role: authReducerState.role });
    }
  }, [authReducerState.role]);

  useEffect(() => {
    if (authReducerState.isAuthenticated) {
      history.push("/guide");
    }
  }, [authReducerState.isAuthenticated]);

  const handleClosDialog = () => {
    setOpen(false);
    props.setSignIn();
  };
  const onResolved = () => {};
  // useEffect(() => {
  //   if (refCaptcha) {
  //     console.log(refCaptcha);
  //     refCaptcha?.current?.callbacks?.execute();
  //   }
  // }, []);
  return (
    <>
      {/* <script src="https://www.google.com/recaptcha/api.js?render=6LfafLAcAAAAACyDF2dNAZaHUVJA4Fslb0NnASZz"></script> */}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Recaptcha
          ref={refCaptcha}
          sitekey={process.env.REACT_APP_SITE_KEY}
          onResolved={onResolved}
        />
        <div className={classes.root}>
          <TextField
           onChange={(e)=>setValue("email",e.target.value?.trim())}
            inputRef={register({
              required: intl.formatMessage({ id: "requiredEmail" }),
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: intl.formatMessage({ id: "invalidEmail" }),
              },
            })}
            label={intl.formatMessage({ id: "email" })}
            name="email"
            helperText={errors.email && errors.email.message}
            error={!!errors.email}
            type="text"
          />

          <TextField
            inputRef={register({
              required: "first name is required!",
            })}
            label="First Name"
            name="firstName"
            error={!!errors.firstName}
            helperText={errors.firstName && errors.firstName.message}
            type="text"
          />

          <TextField
            inputRef={register({
              required: "last name is required!",
            })}
            label="Last Name"
            name="lastName"
            error={!!errors.lastName}
            helperText={errors.lastName && errors.lastName.message}
            type="text"
          />

          <TextField
            inputRef={register({
              required: "username is required!",
            })}
            label="Username"
            name="username"
            error={!!errors.username}
            helperText={errors.username && errors.username.message}
            type="text"
          />

          <TextField
            className={classes.inputField}
            type={values.showPassword ? "text" : "password"}
            label={intl.formatMessage({ id: "password" })}
            inputRef={register({
              required: intl.formatMessage({ id: "requiredPassword" }),
              minLength: {
                value: 5,
                message: intl.formatMessage({ id: "errorPassword" }),
              },
            })}
            name="password"
            error={!!errors.password}
            helperText={errors.password && errors.password.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} edge="end">
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            className={classes.inputField}
            type={values.showConfirmPassword ? "text" : "password"}
            label={intl.formatMessage({ id: "confirmPassword" })}
            inputRef={register({
              required: intl.formatMessage({ id: "requiredConfirmPassword" }),
              validate: (value) =>
                value === watch("password") || "Passwords don't match.",
            })}
            name="confirmPassword"
            error={!!errors.confirmPassword}
            helperText={
              errors.confirmPassword && errors.confirmPassword.message
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      setValues({
                        ...values,
                        showConfirmPassword: !values.showConfirmPassword,
                      })
                    }
                    edge="end"
                  >
                    {values.showConfirmPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <FormControl error={errors.role} className={classes.inputField}>
            <InputLabel id="role">
              {intl.formatMessage({ id: "role" })}
            </InputLabel>
            <Select
              id="role"
              name="role"
              value={values.role}
              inputRef={register({})}
              onChange={(e) => {
                setRoleError(false);
                setValues({ ...values, role: e.target.value });
              }}
              style={{ textAlign: "start" }}
            >
              <MenuItem value="">
                <em>{intl.formatMessage({ id: "none" })}</em>
              </MenuItem>
              <MenuItem value="Regular">
                {intl.formatMessage({ id: "student" })}
              </MenuItem>

              <MenuItem value="Instructor">
                {intl.formatMessage({ id: "teacher" })}
              </MenuItem>
            </Select>

            {roleError && (
              <FormHelperText style={{ color: "red" }}>
                role is required!
              </FormHelperText>
            )}
          </FormControl>
          {values?.role === "Instructor" && (
            <>
              <TextField
                inputRef={register({
                  required: "title is required!",
                })}
                label="Title"
                name="title"
                error={!!errors.title}
                helperText={errors.title && errors.title.message}
                type="text"
              />

              <TextField
                inputRef={register({
                  pattern: {
                    value:
                      /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/,
                    message: "Invalid url address!",
                  },
                })}
                label="Website URL"
                name="websiteUrl"
                helperText={errors.websiteUrl && errors.websiteUrl.message}
                error={!!errors.websiteUrl}
                type="text"
              />

              <TextField
                inputRef={register({
                  pattern: {
                    value: /^(https?:\/\/){0,1}(www\.){0,1}facebook\.com/,
                    message: "Invalid facebook url!",
                  },
                })}
                label="Facebook URL"
                name="facebookUrl"
                helperText={errors.facebookUrl && errors.facebookUrl.message}
                error={!!errors.facebookUrl}
                type="text"
              />

              <TextField
                inputRef={register({
                  pattern: {
                    value:
                      /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\.]+)/im,
                    message: "Invalid instagram url!",
                  },
                })}
                label="Instagram URL"
                name="instagramUrl"
                helperText={errors.instagramUrl && errors.instagramUrl.message}
                error={!!errors.instagramUrl}
                type="text"
              />

              <TextField
                inputRef={register({
                  pattern: {
                    value:
                      /(?:(?:http|https):\/\/)?((www\.)?youtube\.com|youtu\.?be)\/.+$/,
                    message: "Invalid youtube url!",
                  },
                })}
                label="Youtube Channel URL"
                name="youtubeChannelUrl"
                helperText={
                  errors.youtubeChannelUrl && errors.youtubeChannelUrl.message
                }
                error={!!errors.youtubeChannelUrl}
                type="text"
              />
            </>
          )}
          <Grid
            item
            xs={12}
            style={{ marginTop: "15px", paddingBottom: "15px" }}
          >
            <Typography className={classes.privacy}>
              By clicking Sign Up, you agree to our{" "}
              <a
                href={`${process.env.REACT_APP_LANDING_URL}/terms`}
                target="_blank"
              >
                Terms & Conditions
              </a>{" "}
              and{" "}
              <a
                href={`${process.env.REACT_APP_LANDING_URL}/privacy`}
                target="_blank"
              >
                privacy policies.
              </a>
            </Typography>
          </Grid>
          {isDesktop ? (
            <Button
              type="submit"
              id="action"
              variant="contained"
              disabled={authReducerState.loader}
              className={classes.signUpBtn}
            >
              {authReducerState.loader ? (
                <CircularProgress size={15} color="primary" />
              ) : (
                intl.formatMessage({ id: "sign_up" })
              )}
            </Button>
          ) : (
            <Fab
              type="submit"
              size="medium"
              id="action"
              color="primary"
              className={classes.signInBtnIcon}
            >
              {authReducerState.loader ? (
                <CircularProgress size={15} color="primary" />
              ) : (
                <ArrowForwardIos className={classes.forwardIcon} />
              )}
            </Fab>
          )}
        </div>
      </form>
      <Modal disableEnforceFocus={true} open={open}>
        <Grid
          container
          direction="row"
          spacing={3}
          style={modalStyle}
          className={classes.paper}
        >
          <Grid item className={classes.msgBody}>
            <Typography className={classes.modelBodyText}>{open}</Typography>
          </Grid>
          <Grid item className={classes.modalBtnGrid}>
            <Button onClick={handleClosDialog} className={classes.modelBtn}>
              Okay
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default Signup;
