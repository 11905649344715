import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Fab,
  Modal,
  AppBar,
  Tab,
  Typography,
  Box,
  useMediaQuery,
  Tabs,
  CircularProgress,
  Button,
  FormControlLabel,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles, useTheme, withStyles } from "@material-ui/styles";

import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import CardWithThumbnail from "./InviteFriendCard";
import CreatorIntro from "./CreatorIntro";
import PriceAndPlanCard from "./priceAndPlanCard";
import AddIcon from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import CustomerCardList from "../CustomerCardList";
import SocialMediaShare from "../../More/Common/SocialMediaShare";
import Schedule from "./Schedule";
import Details from "./Details";
import { useHistory, useParams, Link } from "react-router-dom";
import { Toolbar, IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { getUsersFriends } from "../../../actions/user";
import {
  getSessionDetails,
  joinSession,
  sessionActiveInActive,
  getSessionParticipant,
  joinUnderSubscriptionSession,
} from "../../../actions/Session";
import ShareIcon from "@material-ui/icons/Share";
import SessionStats from "./SessionStats";
import TopBar from "../../../components/AppBar";
import ExpireSubscriptionPopup from "../../../components/Common/ExpireSubscriptionPopup";
import RecommendUpgrade from "../../../components/Common/recommentUpgradeSubscriptionPopup";

import moment from "moment";
const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 30,
      width: "100%",
      backgroundColor: "#09A9BB",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    maxWidth: 30,
    minWidth: 90,

    color: "#09A9BB",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function SessionDetails(props) {
  const classes = useStyles();
  let history = useHistory();
  const params = useParams();
  let dispatch = useDispatch();

  const [value, setValue] = React.useState(0);
  const [openModel, setOpenModel] = useState(false);
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [sessionStatus, setSessionStatus] = useState(false);
  const [recommendUpgrade, setRecommendUpgrade] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [subscriptionExpire, setSubscriptionExpire] = useState(false);
  const [error, setError] = React.useState(null);

  const handleCloseModal = () => {
    setOpenModel(false);
  };
  const handleOpenModal = () => {
    setOpenModel(true);
  };
  const authReducerState = useSelector((state) => state.auth);

  useEffect(() => {
    getData();
    return () => {
      dispatch({
        type: "GET_SESSION_DETAIL",
        payload: {},
      });
    };
  }, []);

  const getData = async () => {
    dispatch(getUsersFriends(authReducerState?.token));
    if (params?.id) {
      const responseData = await dispatch(
        getSessionDetails({
          userid: authReducerState?.user?.id,
          token: authReducerState?.token,
          id: params?.id,
        })
      );

      await dispatch(
        getSessionParticipant({
          token: authReducerState?.token,
          id: params?.id,
        })
      );

      if (responseData?.status === "success") {
        return;
      } else if (responseData?.status === "fail") {
        setError(responseData?.message ? responseData?.message : "Not found");
      } else if (responseData) {
        setError("Not found");
      }
    }
  };

  const joinNow = async () => {
    if (selectedSession?.plan === "free") {
      const data = { challengeOrSessionId: params?.id, type: "Session" };
      const responseData = await dispatch(
        joinSession({
          token: authReducerState?.token,
          user: authReducerState?.user,
          data: data,
        })
      );
      if (responseData?.success) {
        setRecommendUpgrade(true);
        setShareOpen("Share");
      }
    } else if (selectedSession?.plan === "paid") {
      setOpenPaymentDialog(true);
    } else if (selectedSession?.plan === "subscription") {
      const data = { sessionId: params?.id, type: "Session" };
      const responseData = await dispatch(
        joinUnderSubscriptionSession({
          token: authReducerState?.token,
          user: authReducerState?.user,
          data: data,
        })
      );
      if (responseData?.success) {
        setRecommendUpgrade(true);
        setShareOpen("Share");
      }
    }
  };

  let selectedSession = useSelector((state) => state.session.selectedSession);
  let showLoader = useSelector((state) => state.session.showLoader);
  const [invited, setInvited] = useState(
    props.sessionData && props.sessionData.userIds
      ? props.sessionData.userIds
      : []
  );
  const [isJoined, setIsJoined] = useState(false);
  useEffect(() => {
    setIsJoined(selectedSession?.isJoined);
    setSessionStatus(selectedSession?.status === "Active" ? true : false);
  }, [selectedSession, selectedSession?.isJoined]);
  const intl = useIntl();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const userFriends = useSelector((state) => state?.userRelations?.userFriends);
  let configurations = useSelector((state) => state?.setting?.siteConfig);
  let joined = useSelector((state) => state.session.joined);
  let reducerUser = useSelector((state) => state.user?.user);


  function getImage(props) {
    return <div>{props}</div>;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleInvite = (id) => {
    let found = invited.indexOf(id);
    if (found === -1) {
      setInvited([...invited, id]);
    } else {
      let invite = [...invited];
      invite.splice(found, 1);
      setInvited([...invite]);
    }
  };
  const chcekInvited = (id) => {
    if (invited.indexOf(id) === -1) {
      return false;
    } else {
      return true;
    }
  };
  const [modalStyle] = useState(getModalStyle);
  function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
      borderColor: theme.colors.white,
      borderRadius: "4px",
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top - 4}%, -${left}%)`,
    };
  }
  const modalBody = (
    <Grid
      container
      direction="row"
      spacing={3}
      style={modalStyle}
      className={classes.paper}
    >
      <Grid item className={classes.msgBody}>
        <Typography className={classes.modelBodyText}>
          {intl.formatMessage({ id: "sessionNotStartedYet" })}
        </Typography>
      </Grid>
      <Grid item className={classes.modalBtnGrid}>
        <Button onClick={() => handleCloseModal()} className={classes.modelBtn}>
          {intl.formatMessage({ id: "okay" })}
        </Button>
      </Grid>
    </Grid>
  );
  const verifyTime = () => {
    if (
      selectedSession?.user?.id !== authReducerState?.user?.id &&
      reducerUser?.isExpiredSubscription &&
      selectedSession?.plan === "subscription"
    ) {
      setSubscriptionExpire(true);
    } else {
      history.push({
        pathname: "/play-session",
        state: { ...selectedSession },
      });
    }
  };

  const CustomSwitch = withStyles({
    switchBase: {
      "&$checked": {
        color: "#29cc10",
      },
      "&$checked + $track": {
        backgroundColor: "#29cc10",
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const handleStatusChange = () => {
    dispatch(
      sessionActiveInActive({
        token: authReducerState?.token,
        sessionId: params?.id,
        status: sessionStatus ? "InActive" : "Active",
      })
    );
    return;
  };

  const [open, setOpen] = React.useState(false);

  const handleOpenSoftwareDownload = () => {
    setOpen(true);
  };

  const handleCloseSoftwareDownload = () => {
    setOpen(false);
  };

  return (
    <>
      <RecommendUpgrade
        recommend={recommendUpgrade}
        setRecommendUpgrade={() => setRecommendUpgrade(false)}
      />
      {error ? (
        <Grid container style={{ backgroundColor: "#E5E5E5" }}>
          <Grid
            item
            xs={11}
            style={{ display: "flex", alignItems: "center", height: "87px" }}
          >
            <TopBar
              appBarConfig={{
                show: true,
                text: "Session",
                backgroundColor: "#E5E5E5",
              }}
            />
          </Grid>
          <Grid xs={12} item>
            <div
              style={{
                background: "#E5E5E5",
                height: "calc(100vh - 87px)",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Typography
                style={{
                  fontFamily: theme.fontFamily.Roboto,
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "24px",
                  lineHeight: "28px",
                  color: "#000000",
                  marginTop: "7px",
                }}
              >
                {error}
              </Typography>
            </div>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid
            container
            style={{ background: "#FBFAF9", paddingBottom: isMobile && "90px" }}
          >
            <Modal
              disableEnforceFocus={true}
              open={openModel}
              onClose={handleCloseModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              {modalBody}
            </Modal>
            <CustomerCardList
              open={openPaymentDialog}
              setRecommendUpgrade={setRecommendUpgrade}
              setShareOpen={setShareOpen}
              setOpen={setOpenPaymentDialog}
              sessionId={selectedSession?.id}
              plan={{
                name:
                  selectedSession?.plan === "free"
                    ? "Free"
                    : selectedSession?.plan === "subscription"
                    ? "Subscription"
                    : "Paid",
              }}
              price={selectedSession?.price}
            />

            <Grid container item xs={12}>
              <Grid item xs={3}>
                <AppBar
                  className={classes.appBar1}
                  style={{ backgroundColor: "rgb(251, 250, 249)" }}
                >
                  <IconButton
                    onClick={() => history.goBack()}
                    className={classes.backButton}
                  >
                    <ArrowBack />
                  </IconButton>
                  <Toolbar>
                    <Typography className={classes.appBarText} variant="h6">
                      {intl.formatMessage({ id: "back" })}
                    </Typography>
                  </Toolbar>
                </AppBar>
              </Grid>

              <Grid
                item
                xs={9}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {(selectedSession.status === "Pending" ||
                  selectedSession.status === "Rejected") &&
                  moment(
                    moment(
                      selectedSession?.sessionDates &&
                        selectedSession?.sessionDates[0]?.dateTime,
                      "x"
                    ).local()
                  ).isAfter(moment()) &&
                  selectedSession?.userId === authReducerState?.user?.id && (
                    <Link to={`/more/session/edit/${params?.id}`}>
                      <IconButton>
                        <Edit style={{ color: "#09A9BB" }} />
                      </IconButton>
                    </Link>
                  )}

                {selectedSession?.userId === authReducerState?.user?.id && (
                  <FormControlLabel
                    control={
                      <CustomSwitch
                        onChange={handleStatusChange}
                        checked={sessionStatus}
                        disableRipple
                        disabled={
                          selectedSession?.status === "Active" ||
                          selectedSession?.status === "InActive"
                            ? false
                            : true
                        }
                      />
                    }
                    label={
                      <span className={classes.statusTxt}>
                        {selectedSession?.status}
                      </span>
                    }
                  />
                )}
              </Grid>
            </Grid>
            {showLoader ? (
              <Grid container justify="center">
                <CircularProgress size={30} style={{ color: "#09A9BB" }} />
              </Grid>
            ) : (
              <>
                <Grid item container md={6} xs={12}>
                  <Grid item xs={3} sm={7} md={3} lg={3} xl={7}>
                    <AppBar position="static" className={classes.appBar}>
                      <StyledTabs
                        value={value}
                        onChange={handleChange}
                        aria-label="styled tabs example"
                      >
                        <StyledTab
                          label={intl.formatMessage({ id: "details" })}
                        />
                        {authReducerState?.user?.id ===
                          selectedSession?.userId && (
                          <StyledTab
                            label={intl.formatMessage({ id: "stats" })}
                          />
                        )}
                      </StyledTabs>
                    </AppBar>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    sm={5}
                    md={5}
                    lg={5}
                    xl={5}
                    style={{
                      display: "flex",
                      marginTop: "3px",
                      justifyContent: "center",
                    }}
                  >
                    {selectedSession?.userId === authReducerState?.user?.id &&
                    selectedSession?.sessionDates?.length > 0 &&
                    moment().isBefore(
                      moment(
                        selectedSession?.sessionDates[0]?.endingDateTime,
                        "x"
                      ).local()
                    ) ? (
                      <>
                        <Typography
                          className={classes.popoverLinkStyle}
                          onClick={handleOpenSoftwareDownload}
                          disabled={selectedSession?.status !== "Active"}
                        >
                          Download Streaming Software
                        </Typography>
                        <Dialog
                          open={open}
                          keepMounted
                          onClose={handleCloseSoftwareDownload}
                          aria-labelledby="alert-dialog-slide-title"
                          aria-describedby="alert-dialog-slide-description"
                        >
                          <DialogTitle id="alert-dialog-slide-title">
                            {"Download streaming software?"}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                              For delivering session you must have an{" "}
                              <span>
                                <a
                                  href="https://help.livestream.com/hc/en-us/articles/360002053268-How-to-Install-Livestream-Studio"
                                  target="__blank"
                                >
                                  Encoding Live Streaming Software
                                </a>
                              </span>
                              . If you have it already ignore this otherwise
                              click on link to download.
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={handleCloseSoftwareDownload}
                              color="primary"
                            >
                              Ok
                            </Button>
                          </DialogActions>
                        </Dialog>{" "}
                      </>
                    ) : joined ||
                      selectedSession?.userId === authReducerState?.user?.id ? (
                      <Button
                        className={classes.linkStyle}
                        onClick={verifyTime}
                        disabled={selectedSession?.status !== "Active"}
                      >
                        {selectedSession?.sessionDates?.length > 0 &&
                        moment().isAfter(
                          moment(
                            selectedSession?.sessionDates[0]?.endingDateTime,
                            "x"
                          ).local()
                        )
                          ? intl.formatMessage({ id: "Play Session" })
                          : intl.formatMessage({ id: "joinLive" })}
                      </Button>
                    ) : (
                      <Button
                        className={classes.linkStyle}
                        onClick={() => {
                          joinNow();
                        }}
                      >
                        {selectedSession?.plan === "free"
                          ? intl.formatMessage({ id: "joinNowFree" })
                          : selectedSession?.plan === "subscription"
                          ? `${intl.formatMessage({ id: "joinNow" })}`
                          : `${intl.formatMessage({ id: "joinNow" })} ${
                              configurations?.currency
                            } ${selectedSession?.price || 0}`}
                      </Button>
                    )}
                    {/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& */}
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    xs={4}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      className={classes.subscribeBtn}
                      // style={{color: "blue", fontWeight : '700'}}
                      //  disabled={!courseDetail?.id }

                      onClick={() => history.push("/more/subscription-plan")}
                    >
                      <Typography>
                        {reducerUser?.purchasedSubscription?.SubscriptionPlan
                          ?.name === "free" &&
                        reducerUser?.purchasedSubscription.length === 0
                          ? "SUBSCRIBE"
                          : "UPGRADE"}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>

                <Grid item md={12} xs={12}>
                  {subscriptionExpire && (
                    <ExpireSubscriptionPopup
                      subscriptionExpire={subscriptionExpire}
                      setPopUp={() => setSubscriptionExpire(false)}
                    />
                  )}
                  <TabPanel
                    style={{ padding: "0px", width: "100%" }}
                    value={value}
                    index={0}
                  >
                    <Grid container style={{ padding: "0px 20px" }}>
                      <Grid
                        item
                        container
                        md={12}
                        xs={12}
                        spacing={!isMobile && 2}
                        justify={
                          selectedSession?.type === "Schedule" || isMobile
                            ? "center"
                            : ""
                        }
                      >
                        <Grid item md={6} xs={12}>
                          <Details
                            selectedSession={selectedSession}
                            shareOpen={shareOpen}
                          />
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <CreatorIntro />
                          <PriceAndPlanCard selectedSession={selectedSession} />

                          {selectedSession?.type === "Schedule" && (
                            <Schedule selectedSession={selectedSession} />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </TabPanel>

                  <TabPanel value={value} index={1}>
                    <Grid container>
                      <Grid
                        item
                        lg={12}
                        xs={12}
                        style={{ padding: "0px 20px", minHeight: "100vh" }}
                      >
                        <SessionStats />
                      </Grid>
                    </Grid>
                  </TabPanel>
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
    </>
  );
}

export default SessionDetails;

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    height: 330,
    maxWidth: 315,
    width: "100%",
    minWidth: 300,
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    outline: "none",
  },

  modelBodyText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "17px",
    lineHeight: "27px",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: "#192247",
  },

  modelBtn: {
    height: "73px",
    width: "100%",
    fontFamily: theme.fontFamily.Roboto,
    cursor: "pointer",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    color: theme.colors.white,
  },

  modalBtnGrid: {
    padding: "0px",
    alignSelf: "flex-end",
    color: "#fff",
    cursor: "pointer",
    background: `linear-gradient(#09A9BB 1%,#09A9BB 100%)`,
    height: "73px",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  msgBody: {
    minHeight: "240px",
    alignItems: "center",
    display: "flex",
  },
  linkStyle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    width: "100%",
    transform: "rotate(360deg)",
    background: "linear-gradient(360deg, #15AEBF 0%, #A6D6FF 100%)",
    borderRadius: "10px",
    color: "#FFF",
    textDecoration: "none",
    marginRight: "20px",
  },
  addedFriendCard: {
    background: "#F2F2F2",
    boxShadow: "inset 2px 2px 4px rgba(0, 65, 72, 0.05)",
    borderRadius: "12px",
    paddingTop: "10px",
    marginTop: "10px",
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
  },
  noContent: {
    height: "35vh",
    color: "#7d7b79",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    padding: "12px 10px 10px 17px",
    textAlign: "center",
  },
  containerStyle: {
    marginBottom: "5px",
  },
  CancelBtnPosition: {
    width: "100vw",
    position: "fixed",
    bottom: "0px",
    boxShadow: "0px -12px 12px rgba(0, 0, 0, 0.03)",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    zIndex: 100,
    background: "0px",
  },
  NextBtnPosition: {
    width: "100vw",
    position: "fixed",
    bottom: "60px",
    boxShadow: "0px -12px 12px rgba(0, 0, 0, 0.03)",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    zIndex: 101,
  },
  subTitle: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontWeight: "bold",
    fontSize: "8px",
    lineHeight: "9px",
    color: "#3C4A6B",
    textAlign: "center",
  },
  appBar: {
    backgroundColor: "#FBFAF9",
    width: "100%",
    boxShadow: "none",
    display: "flex",
    justifyContent: "space-between",
  },
  headerGrid: {
    background: "#FBF1E0",
    height: "2.75em",
    fontSize: "20px",
    boxShadow: `inset 2px 2px 4px rgba(0, 65, 72, 0.05)`,
    borderRadius: `12px`,
  },
  selectedBtn: {
    background: "linear-gradient(180deg, #FFC000 -13.24%, #F28705 100%)",
    width: "100%",
    height: "2.750em",
    borderRadius: "11px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
    fontSize: "20px",
    lineHeight: "23px",
    color: "#fff",
    marginBottom: "70px",
  },
  unselectedBtn: {
    width: "100%",
    height: "2.750em",
    borderRadius: "11px",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "none",
    fontSize: "20px",
    lineHeight: "23px",
    color: "#F8A303",
    marginBottom: "70px",
  },
  tabLabel: {
    color: "#DFDFDF",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    minWidth: "27%",
    textTransform: "none",
  },
  subscribeBtn: {
    height: "2.463em",
    width: "95%",
    background: "#4DB051",
    borderRadius: "10px",
    transform: "rotate(360deg)",
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "10px",
    color: "#FFFFFF",
  },
  tabSelected: {
    color: "#F28705",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
  },
  indicatorStyling: {
    backgroundColor: "#F28705",
    height: "5px",
    maxWidth: "60px",
  },
  indicatorStylingMobile: {
    backgroundColor: "#F28705",
    height: "5px",
  },
  tabsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  appBar1: {
    color: "#000",
    flexDirection: "row",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    right: "unset",
    position: "relative",
    marginLeft: "25px",
    boxShadow: "none",
  },
  appBarMobile: {
    background: "#FFFFFF",
    color: "#000",
    flexDirection: "row",
    padding: theme.spacing(1.5),
    justifyContent: "space-between",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    right: "unset",
    position: "sticky",
  },
  appBarText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "20px",
    lineHeight: "23px",
  },
  appBarTextMobile: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "26px",
    lineHeight: "35px",
  },
  backButton: {
    background: "transparent",
    color: "#000",
    padding: "0px",
  },
  speedDial: {
    position: "fixed",
    top: theme.spacing(15),

    "& .MuiFab-primary:hover": {
      width: "50px",
      height: "50px",
      color: "#E54D40",
      backgroundColor: "#E5E5E5 !important",
    },
    "& .MuiFab-primary": {
      width: "50px",
      height: "50px",
      backgroundColor: "#F28705 !important",
    },
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(3),
      left: theme.spacing(2),
    },
  },
  statusTxt: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "500",
    fontsize: "14px",
    lineHeight: "20px",
    color: "#606060",
    marginTop: "10px",
  },
  popoverLinkStyle: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "11px",
    lineHeight: "20px",
    color: "#15AEBF",
    marginTop: "10px",
    cursor: "pointer",
    marginRight: "20px",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  typography: {
    padding: theme.spacing(2),
  },
}));
