import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Typography, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, Link } from 'react-router-dom'
import {
    getAllCategories
} from "../../../actions/courses";

function CategoriesFilter(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const categoriesList = useSelector(state => state?.courses?.categoriesList);
    const authReducerState = useSelector(state => state.auth);
    const [selectedCategory, setSelectedCategory] = useState(props?.my ? 'my' : '');
console.log(categoriesList,"categoriesList")
    useEffect(() => {
        dispatch(getAllCategories({ token: authReducerState?.token }));
    }, []);

    const handleClick = (id) => {
        setSelectedCategory(id)
        return props?.handleSelectedCategory(id)
    }
    const handleClickCategory = (id) => {
        setSelectedCategory(id)
        props?.handleSelectedCategory(id)
    // history.push(`/view-category-courses/${id}`)


    }

    return (
        <Grid container className={classes.scrollBar}>
            <Grid item >
                <Button className={selectedCategory === '' ? classes.categoryTagSelected : classes.categoryTag} onClick={() => handleClick('')}>
                <Typography className={selectedCategory === '' ? classes.textStyleSelected : classes.textStyle}>{'All'}</Typography>
                </Button>
            </Grid>

           {props?.my && <Grid item >
                <Button className={selectedCategory === 'my' ? classes.categoryTagSelected : classes.categoryTag} onClick={() => handleClick('my')}>
                <Typography className={selectedCategory === 'my' ? classes.textStyleSelected : classes.textStyle}>{'My'}</Typography>
                </Button>
            </Grid>}
            {
                categoriesList?.map((category) => (
                    <Grid item key={category?.id} >
                         <Link
                                  to={{ pathname: `/category/${category?.name}`, state: { category } }}
                                  style={{textDecoration: "none"}}
                                >
                                 <Button className={category?.id === selectedCategory ? classes.categoryTagSelected : classes.categoryTag} key={category?.id} onClick={() => handleClickCategory(category?.id)}>
                            <Typography className={category?.id === selectedCategory ? classes.textStyleSelected : classes.textStyle}>{category?.name}</Typography>
                        </Button>
                                </Link>

                    </Grid>
                ))
            }

        </Grid >
    );
}

export default CategoriesFilter;

const useStyles = makeStyles((theme) => ({
    categoryTag: {
        minWidth: '95px',
        height: '38px',
        background: '#FFEFD1',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '15px',
        textTransform:'none'
    },
    textStyle: {
        fontFamily: 'Reef',
        fontStyle: 'normal',
        fontWeight: 900,
        fontSize: '18px',
        lineHeight: '25px',
        color: '#343434',
        width:'max-content'
    },
    categoryTagSelected: {
        minWidth: '95px',
        height: '38px',
        background: '#FBAE02 !important',
        borderRadius: '5px',
        marginRight: '15px',
        textTransform:'none'
    },
    textStyleSelected: {
        fontFamily: 'Reef',
        fontStyle: 'normal',
        fontWeight: 900,
        fontSize: '18px',
        lineHeight: '25px',
        color: '#FFFFFF',
        width:'max-content'

    },
    scrollBar: {
        flexWrap: "unset",
        overflowX: "auto",
        "&::-webkit-scrollbar": {
            display: "none"
        }
    },

}))