import { useEffect, useState } from 'react';
import { Grid, Typography, Avatar, Divider, Button, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from 'react-router-dom'
import { useIntl } from "react-intl";
import { getUserFollowersFollowingsById } from '../../../../actions/publicUser';


function ProfileInfo(props) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(state => state?.user?.user);
    const authReducer = useSelector((state) => state?.auth);
    const userFriends = useSelector(state => state?.userRelations?.userFriends);
    const intl = useIntl();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [userFollowingsFollowers, setUserFollowingsFollowers] = useState('');



    const getData = async () => {
        const res = await dispatch(
            getUserFollowersFollowingsById({ token: authReducer?.token, userId: user?.id })
        );
        if (res) {
            setUserFollowingsFollowers(res)
        }
    }

    useEffect(() => {
        user?.id && getData()
    }, [user]);

    return (
        <>

            <Grid container style={{ marginBottom: '20px' }}>
                <Grid container item xs={12} sm={12} md={8} lg={6} xl={6} style={{ display: 'flex', justifyContent: 'center', paddingRight: '30px' }}>
                    <div className={classes.basicInfoBox}
                        style={{ backgroundImage: `url(${user?.coverUrl}),linear-gradient(180deg, rgba(255, 255, 255, 0.23) 0%, rgba(0, 0, 0, 0.23) 39.58%, #000000 100%)`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', display: 'flex', alignItems: 'center' }}>
                        <Grid container direction='row' style={{ marginTop: '-46.25%', marginLeft: "2rem" }}>
                            <Grid item xs={10}>
                                <Typography className={classes.name}>{user?.name}

                                </Typography>
                                <Typography className={classes.username}>@{user?.username}</Typography>
                            </Grid>
                            <Grid item xs={2}>

                            </Grid>
                        </Grid>
                    </div>
                    <div style={{ marginTop: '-70px' }}>
                        <Avatar src={user?.pictureUrl} className={classes.userPhotoWeb} style={{ margin: '15px auto', height: '110px', width: '110px' }} />
                    </div>
                </Grid>


                <Grid item container xs={12}  >
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={6} container style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', paddingRight: '30px' }}>
                        <Grid item xs={2} sm={2} md={2} lg={2}  >
                            <Link to='/more/connections/friends' style={{ textDecoration: 'none' }}>

                                <Typography className={classes.counts}>{userFriends?.length}</Typography>
                                <Typography className={classes.countsType}>
                                    {intl.formatMessage({ id: "friends" })}
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}  >
                            <Link to='/more/connections/following' style={{ textDecoration: 'none' }}>

                                <Typography className={classes.counts}>
                                    {userFollowingsFollowers?.followings?.length || 0}
                                    {/* {user?.followings?.length} */}
                                </Typography>
                                <Typography className={classes.countsType}>
                                    {intl.formatMessage({ id: "following" })}
                                </Typography>
                            </Link>

                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}  >
                            <Link to='/more/connections/followers' style={{ textDecoration: 'none' }}>

                                <Typography className={classes.counts}>
                                    {userFollowingsFollowers?.followers?.length || 0}

                                </Typography>
                                <Typography className={classes.countsType}>
                                    {intl.formatMessage({ id: "followers" })}
                                </Typography>
                            </Link>

                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}  >
                            <Button
                                disableRipple={true}
                                className={classes.customButton}
                                onClick={() => history.push("/edit-profile-info")}>
                                {'Edit profile'}
                            </Button>

                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3}  >
                            <Button
                                disableRipple={true}
                                className={classes.customButton}
                                onClick={() => history.push("/more/connections")}>
                                {'Connections'}
                            </Button>

                        </Grid>
                    </Grid>
                </Grid>

                <Divider />
                <Grid item container xs={10} style={{ textAlign: 'left' }} >
                    <Grid item container xs={12} style={{ justify: 'space-between', }} >
                        <Grid item sm={12} md={6} container style={{ height: '300px', paddingRight: "50px" }} >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Typography className={classes.infoTitle}>
                                    {intl.formatMessage({ id: "title" })}
                                </Typography>
                                <Typography className={classes.infoSubTitle}>{user?.title}</Typography>
                                <Divider className={classes.hrLine} style={{ marginTop: !user?.title && '15px' }} />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Typography className={classes.infoTitle}>
                                    {intl.formatMessage({ id: "emailAddress" })}
                                </Typography>
                                <Typography className={classes.infoSubTitle}>{user?.email}</Typography>
                                <Divider className={classes.hrLine} style={{ marginTop: !user?.email && '15px' }} />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Typography className={classes.infoTitle}>
                                    {intl.formatMessage({ id: "city" })}
                                </Typography>
                                <Typography className={classes.infoSubTitle}>{user?.city}</Typography>
                                <Divider className={classes.hrLine} style={{ marginTop: !user?.city && '15px' }} />
                            </Grid>

                            {user?.role !== 'Regular' && <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Typography className={classes.infoTitle}>
                                    {intl.formatMessage({ id: "websiteUrl" })}
                                </Typography>
                                <a href={/(http(s?)):\/\//i.test(user?.websiteUrl) ? user?.websiteUrl : `http://${user?.websiteUrl}`} target='_blank' className={classes.link}>
                                    <Typography className={classes.infoSubTitle}>{user?.websiteUrl}</Typography>
                                </a>
                                <Divider className={classes.hrLine} style={{ marginTop: !user?.websiteUrl && '15px' }} />
                            </Grid>}


                        </Grid>

                        <Grid item sm={12} md={6} container style={{ paddingRight: "50px", marginTop: isMobile && '25px' }}>
                            {user?.role !== 'Regular' && <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Typography className={classes.infoTitle}>
                                    {intl.formatMessage({ id: "facebookUrl" })}
                                </Typography>
                                <a href={/(http(s?)):\/\//i.test(user?.facebookUrl) ? user?.facebookUrl : `http://${user?.facebookUrl}`} target='_blank' className={classes.link}>
                                    <Typography className={classes.infoSubTitle}>{user?.facebookUrl}</Typography>
                                </a>
                                <Divider className={classes.hrLine} style={{ marginTop: !user?.facebookUrl && '15px' }} />
                            </Grid>}

                            {user?.role !== 'Regular' && <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >

                                <Typography className={classes.infoTitle}>
                                    {intl.formatMessage({ id: "instagramUrl" })}
                                </Typography>
                                <a href={/(http(s?)):\/\//i.test(user?.instagramUrl) ? user?.instagramUrl : `http://${user?.instagramUrl}`} target='_blank' className={classes.link}>
                                    <Typography className={classes.infoSubTitle}>{user?.instagramUrl}</Typography>
                                </a>
                                <Divider className={classes.hrLine} style={{ marginTop: !user?.instagramUrl && '15px' }} />
                            </Grid>}

                            {user?.role !== 'Regular' && <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Typography className={classes.infoTitle}>
                                    {intl.formatMessage({ id: "youtubeChannelUrl" })}
                                </Typography>
                                <a href={/(http(s?)):\/\//i.test(user?.youtubeChannelUrl) ? user?.youtubeChannelUrl : `http://${user?.youtubeChannelUrl}`} target='_blank' className={classes.link}>

                                    <Typography className={classes.infoSubTitle}>{user?.youtubeChannelUrl}</Typography></a>
                                <Divider className={classes.hrLine} style={{ marginTop: !user?.youtubeChannelUrl && '15px' }} />
                            </Grid>}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Typography className={classes.infoTitle}>
                                    {intl.formatMessage({ id: "description" })}
                                </Typography>
                                <Typography className={[classes.description, classes.infoSubTitle]}>{user?.id && user?.role !== 'Regular' ? <div dangerouslySetInnerHTML={{ __html: unescape(user?.description) }} /> : user?.description}</Typography></Grid>

                        </Grid>

                    </Grid>

                </Grid>
            </Grid>
        </>
    );
}
const useStyles = makeStyles(theme => ({
    basicInfoBox: {
        width: '100%',
        borderRadius: '11px',
        paddingLeft: '20px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        position: "relative",
        height: 0,
        paddingTop: `56.25%`,
    },
    playerWrapper: {
        position: 'relative',
        paddingTop: '56.25'
    },
    reactPlayer: {
        position: 'absolute',
        top: 0,
        left: 0
    },
    name: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        color: '#ffffff',
        textAlign: 'left',
    },
    username: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        textAlign: 'left',
        color: '#ffffff',
    },
    hrLine: {
        width: '100%',
        border: '1px solid #ededed',
    },
    infoTitle: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '21px',
        color: '#3c4a6b',
        paddingBottom: '10px',
        marginTop: '25px',
    },
    infoSubTitle: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '16px',
        paddingBottom: '8px',
        color: '#7b92a6',
        opacity: 0.78,
    },
    description: {
        width: '345px',
        minHeight: '122px',
        border: '1px solid #d6d6d6',
        boxSizing: 'border-box',
        borderRadius: '11px',
        padding: '10px',
    },
    userPhotoWeb: {
        height: '70px',
        width: '70px',
        textAlign: 'center',
    },
    counts: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '23px',
        color: '#09a9bb',

    },
    countsType: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '11px',
        lineHeight: '13px',
        color: '#09a9bb',
        opacity: 0.65,

    },
    editIcon: {
        background: '#6E6E6E !important',
    },
    link: {
        textDecoration: 'none',
        '& :hover': {
            color: '#09a9bb'
        }
    },
    customButton: {
        background: `linear-gradient(360deg, #09A9BB 100%, #09A9BB 0%)`,
        height: "2.463em",
        width: "85%",
        borderRadius: "10px",
        transform: "rotate(360deg)",
        fontFamily: theme.fontFamily.Reef,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "10px",
        color: "#FFFFFF",
    }

}))
export default ProfileInfo;