import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Card,
    Typography,
    CardContent,
    Grid
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux"
import moment from 'moment'
import clsx from 'clsx'



function CourseInstructorIntro(props) {
    const classes = useStyles();
    const intl = useIntl();
    const authReducerState = useSelector(state => state.auth);
    const authUser = useSelector(state => state.user?.user);
    let courseDetail = useSelector(state => state?.courses?.courseDetail);
    const configs = useSelector(state => state?.setting?.siteConfig);

    return (
        <>
            <Card className={classes.root}>
                <CardContent>
                    <Grid container>

                        {/* <Grid item xs={12}>
                            <Grid container>
                                <Typography className={clsx(classes.font_family, classes.heading)}>
                                    Course Plan
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography
                                className={clsx(classes.font_family, classes.description)}>
                                {courseDetail?.plan?.name}
                            </Typography>

                        </Grid> */}
                        <>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Typography className={clsx(classes.font_family, classes.heading)}>
                                        Course Price
                                </Typography>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography
                                    className={clsx(classes.font_family, classes.description)}>
                                    {courseDetail?.plan?.name === 'Subscription' ? 'Under Subscription' : (courseDetail?.plan?.name === 'Free' || courseDetail?.plan?.name === 'Donation') ? 'Free' : `${configs?.currency} ${courseDetail?.price}`}
                                </Typography>

                            </Grid>

                            <Grid item xs={12}>
                                <Grid container>
                                    <Typography className={clsx(classes.font_family, classes.heading)}>
                                        Course Type
                                </Typography>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography className={clsx(classes.font_family, classes.description)}>
                                    {courseDetail?.user?.isPlatform ? 'Platform Course' : 'Teacher Course'}
                                </Typography>

                            </Grid>
                        </>

                        {(authReducerState?.user?.id !== courseDetail?.user?.id && courseDetail?.plan?.name === 'Subscription' && courseDetail?.isUserTaking) && <>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Typography
                                        className={clsx(classes.font_family, classes.heading)}>
                                        Availability
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography
                                    className={clsx(classes.font_family, classes.description)}>
                                    This is under subscription Course and will be available from {authUser?.purchasedSubscription && moment(authUser?.purchasedSubscription[0]?.startDate).format('DD-MM-YY')} to
                            {authUser?.purchasedSubscription && moment(authUser?.purchasedSubscription[0]?.endDate).format('DD-MM-YY')}
                                </Typography>

                            </Grid></>}
                    </Grid>
                   
                </CardContent>
            </Card>
        </>
    );
}

export default CourseInstructorIntro;

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        borderRadius: "10px",
        boxShadow: "0px 16px 20px rgba(123, 0, 0, 0.05)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
    },
    font_family: {
        fontFamily: theme.fontFamily.Reef,
        fontStyle: "normal",
        fontWeight: 900
    },
    heading: {
        fontSize: "20px",
        lineHeight: "27px",
        color: "#1B1B1B"
    },
    description: {
        fontSize: "16px",
        lineHeight: "26px",
        color: "#B0B0B0",
        letterSpacing: "0.016em",
    }
}));
