import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  useMediaQuery,
  Button,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import TopBar from "../../../components/AppBar";
import { useSelector, useDispatch } from "react-redux";
import { Link,useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { makeStyles } from "@material-ui/styles";
import TeacherCard from "../../../components/Common/UserCard";
import API from "../../../utils/API";
import InfiniteScroll from "react-infinite-scroll-component";
import ViewAllSearchComponent from "../../../components/Common/ViewAllSearchComponent";
import Header from "../../../components/Common/MobileHeader/HeaderWithMoreSearchIcon";
import CategoriesFilter from "../../../components/Common/CategoriesFilters";
import SubscribeNowBtn from "../../../components/Common/SubscribeNowBtn";

const ViewAllTeachers = () => {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const [localLoader, setLocalLoader] = useState(false);
  const [data, setData] = useState([]);
  const [searching, setSearching] = useState(false);
  const [displayMobileSearchBar, setDisplayMobileSearchBar] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("");
  let auth = useSelector((state) => state.auth);
  const searchData = useSelector((state) => state?.courses?.searchData);
  const loader = useSelector((state) => state?.loader?.loader);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let reducerUser = useSelector((state) => state.user?.user);
  const limit = 12;

  useEffect(() => {
    if (data.length === 0 && !searching) {
      getCounts();
    }

    return () => {
      dispatch({
        type: "SEARCH",
        payload: [],
      });
    };
  }, [searching]);

  useEffect(() => {
    if (searching) {
      setPage(1);
      setLocalLoader(loader);
    }
  }, [loader]);

  useEffect(() => {
    if (searching) {
      setData(searchData);
    }
  }, [searchData]);

  useEffect(() => {
    !searching && getCounts();
  }, [selectedCategory]);

  const getData = async (count) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth?.token}`,
      },
      params: {
        page,
        limit,
        sortOrder: "DESC",
        sortBy: "createdAt",
        categoryId: selectedCategory,
      },
    };
    try {
      const res = await API.get(`/api/v1/user/getLatestTeachers`, config);
      let allData = [];
      if (page === 1) {
        allData = await [...res.data.data];
      } else {
        allData = await [...data, ...res.data.data];
      }
      setData(allData);
      setPage(page + 1);
      allData.length >= (count || totalCount) && setHasMore(false);
      setLocalLoader(false);
    } catch (error) {
      console.log(error);
      setLocalLoader(false);
      dispatch({
        type: "SHOW_MESSAGE",
        payload: {
          title: `Failed`,
          message:
            error.response &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : `Something went wrong while fetching data`,
          severity: "error",
        },
      });
    }
  };

  const getCounts = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth?.token}`,
      },
      params: {
        type: "Teacher",
        categoryId: selectedCategory,
      },
    };
    try {
      setLocalLoader(true);
      const res = await API.get(`/api/v1/user/getCount`, config);
      setTotalCount(res?.data?.data * 1);
      getData(res?.data?.data * 1);
    } catch (error) {
      console.log(error);
    }
  };

  const searchState = (isSearched) => {
    setSearching(isSearched);
    if (!isSearched && searching) {
      getData();
      setPage(1);
      setHasMore(true);
    }
  };

  const openMobileSearchBar = () => {
    setDisplayMobileSearchBar(!displayMobileSearchBar);
  };

  const closeMobileSearchBar = () => {
    setSearching(false);
    setPage(1);
    setDisplayMobileSearchBar(!displayMobileSearchBar);
    getData();
  };

  const handleSelectedCategory = (categoryId) => {
    console.log("categoryId", categoryId);
    setPage(1);
    setSelectedCategory(categoryId);
  };

  return (
    <Grid container id="InfiniteScrollParent" className={classes.scroll}>
      {isMobile && (
        <Grid item xs={12} style={{ height: "66px" }}>
          {!displayMobileSearchBar ? (
            <Header text="Teachers" openMobileSearchBar={openMobileSearchBar} />
          ) : (
            <ViewAllSearchComponent
              searchState={searchState}
              searchType={"Instructor"}
              mobileSearch={true}
              closeMobileSearchBar={closeMobileSearchBar}
              categoryId={selectedCategory}
            />
          )}
        </Grid>
      )}

      {!isMobile && (
        <Grid
          item
          xs={12}
          container
          style={{
            minHeight: "90px",
            position: "fixed",
            backgroundColor: "#fff",
            zIndex: 5,
            marginTop: isMobile && "80px",
          }}
        >
          <Grid item xs={12} sm={12} md={2} lg={2} xl={1}>
            <TopBar
              appBarConfig={{ show: true, text: "Teachers" }}
              style={{
                color: "#068594",
                backgroundColor: "#FCFCFC",
                float: "left",
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            md={9}
            lg={9}
            xl={9}
            style={{
              minHeight: "90px",
              alignItems: "center",
              display: "flex",
              padding: isMobile && "0px 15px 0px 15px",
              marginLeft: !isMobile && "25px",
            }}
          >
            <ViewAllSearchComponent
              searchState={searchState}
              searchType={"Instructor"}
              categoryId={selectedCategory}
            />
          </Grid>
        </Grid>
      )}

      <Grid
        item
        xs={12}
        style={{
          padding: isMobile ? "15px 0px 15px 15px" : "15px 0px 15px 25px",
          marginTop: isMobile ? "0px" : "80px",
        }}
      >
        <CategoriesFilter handleSelectedCategory={handleSelectedCategory} />
      </Grid>

      {isMobile && (
        <Grid
          container
          justify="center"
          xs={12}
          sm={3}
          md={3}
          style={{
            marginBottom: "5px",
            display: "flex",
            marginRight: "0px",
          }}
        >
          {/*  <SubscribeNowBtn noMargin={true} /> */}
          <Button
                      className={classes.subscribeBtn}
                      // style={{color: "blue", fontWeight : '700'}}
                      //  disabled={!courseDetail?.id }

                      onClick={() => history.push("/more/subscription-plan")}
                    >
                      <Typography>
                        {reducerUser?.purchasedSubscription?.SubscriptionPlan
                          ?.name === "free" &&
                        reducerUser?.purchasedSubscription.length === 0
                          ? "SUBSCRIBE"
                          : "UPGRADE"}
                      </Typography>
                    </Button>
        </Grid>
      )}

      <Grid
        item
        container
        xs={12}
        style={{
          padding: isMobile ? "0px 0px 80px 15px" : "0px 10px 30px 25px",
        }}
      >
        {localLoader ? (
          <Grid
            item
            xs={12}
            className={classes.center}
            style={{ minHeight: "65vh" }}
          >
            <CircularProgress
              color="primary"
              style={{ color: "#09A9BB" }}
              size={30}
            />
          </Grid>
        ) : data?.length === 0 ? (
          <Grid
            item
            xs={12}
            className={classes.center}
            style={{ minHeight: "65vh" }}
          >
            <Typography className={classes.noContent}>
              No Record found
            </Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <InfiniteScroll
              dataLength={data.length}
              next={getData}
              hasMore={hasMore}
              loader={!searching && hasMore && <h4>Loading...</h4>}
              style={{ overflow: "hidden" }}
              scrollableTarget="InfiniteScrollParent"
            >
              <Grid
                item
                xs={12}
                container
                style={{ minHeight: "65vh" }}
                spacing={2}
              >
                {data?.map((item, ind) => {
                  return (
                    <Grid
                      item
                      xs={6}
                      md={4}
                      lg={3}
                      key={ind}
                      style={{ paddingBottom: "15px" }}
                    >
                      <Link
                        to={`/view-profile/${item?.id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <TeacherCard
                          image={item?.pictureUrl}
                          title={item?.title}
                          name={item?.name}
                          description={item?.title}
                        />
                      </Link>
                    </Grid>
                  );
                })}
              </Grid>
            </InfiniteScroll>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ViewAllTeachers;

const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  noContent: {
    color: "#7d7b79",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  subscribeBtn: {
    height: "2.463em",
    width: "30%",
    background: "#4DB051",
    borderRadius: "10px",
    transform: "rotate(360deg)",
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "10px",
    color: "#FFFFFF",
  },
  scroll: {
    height: "100vh",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent" /* make scrollbar transparent */,
    },
  },
  infinScroll: {
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent" /* make scrollbar transparent */,
    },
  },
}));
