import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  Modal,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Tabs,
  Tab,
  AppBar,
  Box,
  Tooltip,
  IconButton,
  Switch,
  FormControlLabel,
  CircularProgress,
  useMediaQuery,
  Toolbar,
} from "@material-ui/core";
import MetaTags from "react-meta-tags";
import {
  Close,
  Share,
  InsertInvitation,
  PlayCircleOutline,
  HighlightOff,
  ArrowBack,
  Error,
} from "@material-ui/icons";
import InviteAndShare from "../../Common/CoursesContainer/InviteAndShare";
import PropTypes from "prop-types";
import TopBar from "../../../components/AppBar";
import CourseViewEpisodesCard from "../../../components/ViewCourse/CourseViewEpisodesCard";
import CourseReviews from "../../../components/ViewCourse/CourseReviews";
import PostReviewCard from "../../../components/ViewCourse/PostReviewCard";
import CourseInstructorIntroCard from "../../../components/ViewCourse/CourseInstructorIntroCard";
import CoursePlanCard from "../../../components/ViewCourse/CoursePlanCard";
import {
  getCourseDetailById,
  addFavoriteCourse,
  deleteFavoriteCourse,
  joinFreeCourse,
  courseActiveInActive,
  joinUnderSubscriptionCourse,
} from "../../../actions/courses";
import { createChallengeData } from "../../../actions/challenges";
import { useTheme } from "@material-ui/core/styles";
import { Edit } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CustomerCardList from "../../../components/Common/CustomerCardList";
import CourseStats from "../../../components/ViewCourse/CourseStats";
import { useIntl } from "react-intl";
import heartIconSelected from "../../../assets/Icons/favoriteHeartIconSelected.png";
import heartIcon from "../../../assets/Icons/BottomNavigationIcons/heartIcon.png";
import ReactPlayer from "react-player/lazy";
import clsx from "clsx";
import IntroVideo from "../../../assets/videos/OmidIntro.mp4";
import ExpireSubscriptionPopup from "../../../components/Common/ExpireSubscriptionPopup";
import RecommendUpgrade from "../../../components/Common/recommentUpgradeSubscriptionPopup";
import { getPrice } from "../../../common/methods";
const CustomSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: "#29cc10",
    },
    "&$checked + $track": {
      backgroundColor: "#29cc10",
    },
  },
  checked: {},
  track: {},
})(Switch);

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    height: "3px",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "#09A9BB",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    minWidth: "50px !important",
    textTransform: "none",
    color: "#09A9BB",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),

    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function ViewCourseDesktop(props) {
  const classes = useStyles();
  const history = useHistory();
  let dispatch = useDispatch();
  const intl = useIntl();
  const theme = useTheme();
  const [joinDialog, setJoinDialog] = useState(false);
  const [subscribeDialog, setSubscribeDialog] = useState(false);

  const [modalStyle] = useState(getModalStyle);
  const [courseStatus, setCourseStatus] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [modalType, setModalType] = useState("");
  const [noEpisode, setNoEpisode] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [recommendUpgrade, setRecommendUpgrade] = useState(false);
  const [value, setValue] = useState(0);
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [errors, setErrors] = useState(false);
  const [messages, setMessages] = useState("");
  const [preview, setPreview] = useState(false);
  const [introVideo, setIntroVideo] = useState(true);
  const [subscriptionExpire, setSubscriptionExpire] = useState(false);
  let authReducerState = useSelector((state) => state.auth);
  let courseDetail = useSelector((state) => state?.courses?.courseDetail);
  let configurations = useSelector((state) => state?.setting?.siteConfig);
  let reducerUser = useSelector((state) => state?.user?.user);

  const IPFS_BASE_URL = process.env.REACT_APP_IPFS_BASE_URL;

  const videoUrl = introVideo
    ? IntroVideo
    : courseDetail?.ipfsCid
    ? `${IPFS_BASE_URL}/${courseDetail?.ipfsCid}`
    : courseDetail.previewUrl;
  const user = useSelector((state) => state?.user?.user);
  let purchasedSubscriptionInfo =
    user?.purchasedSubscription && user?.purchasedSubscription[0];
  const userEnrolledCoursesState = useSelector(
    (state) => state?.courses?.userEnrolledCoursesList
  );
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  useEffect(() => {
    if (courseDetail?.id) {
      setCourseStatus(courseDetail?.status === "Active" ? true : false);
    }
  }, [courseDetail]);

  function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
      borderColor: theme.colors.white,
      borderRadius: "4px",
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top - 4}%, -${left}%)`,
    };
  }

  const hexToRGB = (hex, alpha) => {
    var r = parseInt(hex && hex.slice(1, 3), 16),
      g = parseInt(hex && hex.slice(3, 5), 16),
      b = parseInt(hex && hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  };

  const modalBody = (
    <Grid
      container
      direction="row"
      spacing={3}
      style={modalStyle}
      className={classes.paper}
    >
      <Grid item xs={12}>
        <HighlightOff
          onClick={() => handleCloseModal()}
          className={classes.closeIcon}
        />
      </Grid>
      <Grid item className={classes.msgBody}>
        <Typography className={classes.modelBodyText}>
          {modalType === "createChallenge"
            ? "Please join course first to create challenge."
            : modalType === "joinCourse"
            ? "You have not joined this course. Please join to get access of this course"
            : "This feature is not available right now you can start  free course or with 0 price only."}
        </Typography>
      </Grid>
      <Grid item className={classes.modalBtnGrid}>
        {modalType === "joinCourse" ? (
          <Button
            className={classes.modelBtn}
            disabled={!courseDetail?.id}
            onClick={() => {
              if (
                courseDetail?.plan?.name === "Donation" ||
                courseDetail?.plan?.name === "Free" ||
                courseDetail?.plan?.name === "Freemium" ||
                courseDetail?.plan?.name === "Subscription" ||
                courseDetail?.plan?.name === "Paid"
              ) {
                /*  {
                setJoinDialog(true);
              } else */ joinCourseHandler();
              }
            }}
          >
            <Typography>
              {/*  {courseDetail?.plan?.name === "Donation" ||
              courseDetail?.plan?.name === "Free"
              ? "Join Now Free" : courseDetail?.plan?.name === "Subscription" ? `Join Now`
                : `Join Now ${configurations?.currency} ${getPrice(courseDetail?.price
                  ? courseDetail?.price
                  : courseDetail?.plan?.price
                    ? courseDetail?.plan?.price
                    : 0,reducerUser?.purchasedSubscription)
                }`} */}
              {courseDetail?.plan?.name === "Donation" ||
              courseDetail?.plan?.name === "Free" ? (
                "Join for free"
              ) : courseDetail?.plan?.name === "Subscription" ? (
                `Subscribe`
              ) : (
                <>
                  {`Join Now For `}
                  {reducerUser?.purchasedSubscription?.length === 0 ? (
                    `${configurations?.currency}${
                      courseDetail?.price
                        ? courseDetail?.price
                        : courseDetail?.plan?.price
                        ? courseDetail?.plan?.price
                        : 0
                    }`
                  ) : (reducerUser?.purchasedSubscription[0]?.interval ===
                      "monthly" &&
                      reducerUser?.purchasedSubscription[0]?.SubscriptionPlan
                        ?.omidDiscount > 0) ||
                    (reducerUser?.purchasedSubscription[0]?.interval ===
                      "annual" &&
                      reducerUser?.purchasedSubscription[0]?.SubscriptionPlan
                        ?.annualOmidDiscount > 0) ? (
                    <strike>
                      {`${configurations?.currency}${
                        courseDetail?.price
                          ? courseDetail?.price
                          : courseDetail?.plan?.price
                          ? courseDetail?.plan?.price
                          : 0
                      }`}
                    </strike>
                  ) : (
                    <></>
                  )}
                  {reducerUser?.purchasedSubscription?.length > 0 && (
                    <span style={{ paddingLeft: "2px" }}>
                      {`(${configurations?.currency}${getPrice(
                        courseDetail?.price
                          ? courseDetail?.price
                          : courseDetail?.plan?.price
                          ? courseDetail?.plan?.price
                          : 0,
                        reducerUser?.purchasedSubscription
                      )})`}
                    </span>
                  )}
                </>
              )}
            </Typography>
          </Button>
        ) : (
          /*  <Button
            onClick={() => handleCloseModal()}
            className={classes.modelBtn}
          >
            {intl.formatMessage({ id: "okay" })}
          </Button> */

          <Button
            className={classes.modelBtn}
            disabled={!courseDetail?.id}
            onClick={() => {
              if (
                courseDetail?.plan?.name === "Donation" ||
                courseDetail?.plan?.name === "Free" ||
                courseDetail?.plan?.name === "Freemium" ||
                courseDetail?.plan?.name === "Subscription" ||
                courseDetail?.plan?.name === "Paid"
              ) {
                /*  {
              setJoinDialog(true);
            } else */ joinCourseHandler();
              }
            }}
          >
            <Typography>
              {/*  {courseDetail?.plan?.name === "Donation" ||
            courseDetail?.plan?.name === "Free"
            ? "Join Now Free" : courseDetail?.plan?.name === "Subscription" ? `Join Now`
              : `Join Now ${configurations?.currency} ${getPrice(courseDetail?.price
                ? courseDetail?.price
                : courseDetail?.plan?.price
                  ? courseDetail?.plan?.price
                  : 0,reducerUser?.purchasedSubscription)
              }`} */}
              {console.log(reducerUser, "reducerUser")}
              {courseDetail?.plan?.name === "Donation" ||
              courseDetail?.plan?.name === "Free" ? (
                "Join for free"
              ) : courseDetail?.plan?.name === "Subscription" ? (
                `Subscribe`
              ) : (
                <>
                  {`Join Now For `}
                  {reducerUser?.purchasedSubscription?.length === 0 ? (
                    `${configurations?.currency}${
                      courseDetail?.price
                        ? courseDetail?.price
                        : courseDetail?.plan?.price
                        ? courseDetail?.plan?.price
                        : 0
                    }`
                  ) : (reducerUser?.purchasedSubscription[0]?.interval ===
                      "monthly" &&
                      reducerUser?.purchasedSubscription[0]?.SubscriptionPlan
                        ?.omidDiscount > 0) ||
                    (reducerUser?.purchasedSubscription[0]?.interval ===
                      "annual" &&
                      reducerUser?.purchasedSubscription[0]?.SubscriptionPlan
                        ?.annualOmidDiscount > 0) ? (
                    <strike>
                      {`${configurations?.currency}${
                        courseDetail?.price
                          ? courseDetail?.price
                          : courseDetail?.plan?.price
                          ? courseDetail?.plan?.price
                          : 0
                      }`}
                    </strike>
                  ) : (
                    <></>
                  )}
                  {reducerUser?.purchasedSubscription?.length > 0 && (
                    <span style={{ paddingLeft: "2px" }}>
                      {`(${configurations?.currency}${getPrice(
                        courseDetail?.price
                          ? courseDetail?.price
                          : courseDetail?.plan?.price
                          ? courseDetail?.plan?.price
                          : 0,
                        reducerUser?.purchasedSubscription
                      )})`}
                    </span>
                  )}
                </>
              )}
            </Typography>
          </Button>
        )}
      </Grid>
    </Grid>
  );

  const noEpisodeBody = (
    <Grid
      container
      direction="row"
      spacing={3}
      style={modalStyle}
      className={classes.paper}
    >
      <Grid item className={classes.msgBody}>
        <Typography className={classes.modelBodyText}>
          No Episode Found
        </Typography>
      </Grid>
      <Grid item className={classes.modalBtnGrid}>
        <Button
          onClick={() => setNoEpisode(false)}
          className={classes.modelBtn}
        >
          {intl.formatMessage({ id: "okay" })}
        </Button>
      </Grid>
    </Grid>
  );

  const handleCloseModal = () => {
    setOpenModel(false);
  };

  const handleCourseFavorite = async () => {
    if (courseDetail?.isUserFan === true) {
      const res = await dispatch(
        deleteFavoriteCourse({
          courseId: courseDetail?.id,
          token: authReducerState.token,
        })
      );
      if (res && res === courseDetail) {
        dispatch(
          getCourseDetailById({
            courseId: courseDetail?.id,
            token: authReducerState.token,
          })
        );
      }
    } else if (courseDetail?.isUserFan === false) {
      const res = await dispatch(
        addFavoriteCourse({
          courseId: courseDetail?.id,
          token: authReducerState.token,
        })
      );
      if (res && res === courseDetail) {
        dispatch(
          getCourseDetailById({
            courseId: courseDetail?.id,
            token: authReducerState.token,
          })
        );
      }
    }
  };

  const joinCourseHandler = async () => {
    if (
      courseDetail?.plan?.name === "Donation" ||
      courseDetail?.plan?.name === "Free" ||
      courseDetail?.plan?.name === "Freemium"
    ) {
      const responseData = await dispatch(
        joinFreeCourse({
          courseId: courseDetail?.id,
          token: authReducerState?.token,
        })
      );

      if (responseData.success) {
        setOpenModel(false);
        setRecommendUpgrade(true);
        setShareOpen("Share");
      } else {
        setErrors(true);
        setMessages(responseData?.message);
        //  history.push("/more/subscription-plan");
      }
    } else if (courseDetail?.plan?.name === "Subscription") {
      const responseData = await dispatch(
        joinUnderSubscriptionCourse({
          courseId: courseDetail?.id,
          token: authReducerState?.token,
        })
      );
      if (responseData.success) {
        setOpenModel(false);
        setRecommendUpgrade(true);
        setShareOpen("Share");
      } else {
        if (
          reducerUser?.purchasedSubscription &&
          reducerUser?.purchasedSubscription.length < 1 &&
          courseDetail?.plan?.name === "Subscription"
        ) {
        } else {
          // setErrors(true)
          // setMessages(responseData?.message)
          history.push("/more/subscription-plan");
        }
      }
    } else {
      setOpenPaymentDialog(true);
      setOpenModel(false);
    }
    if (
      reducerUser?.purchasedSubscription &&
      reducerUser?.purchasedSubscription.length < 1 &&
      courseDetail?.plan?.name === "Subscription"
    ) {
      //  setSubscribeDialog(true);
      history.push("/more/subscription-plan");
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCreateChallenge = () => {
    if (
      courseDetail?.isUserTaking ||
      courseDetail?.userId === authReducerState?.user?.id
    ) {
      dispatch(
        createChallengeData({
          courseInfo: { ...courseDetail },
          category: courseDetail?.category?.name,
        })
      );
      return history.push(`/more/create-challenge`);
    } else {
      setModalType("createChallenge");
      setOpenModel(true);
    }
  };

  const startEpisode = () => {
    if (
      (reducerUser?.isExpiredSubscription &&
        courseDetail?.plan?.name === "Subscription") ||
      (reducerUser?.purchasedSubscription?.length === 0 &&
        courseDetail?.plan?.name === "Subscription")
    ) {
      return setSubscriptionExpire(true);
      // history.push("/more/subscription-plan");
    } else if (courseDetail?.episodes?.length > 0) {
      if (!courseDetail?.isPaid && courseDetail?.plan?.name === "Freemium") {
        let inProgress = "";
        let notStarted = "";
        const freeEpisodes =
          courseDetail?.episodes?.length > 0 &&
          courseDetail?.episodes?.filter((item) => {
            inProgress =
              !inProgress &&
              item?.isFree &&
              item?.watchStatus === "Started" &&
              item?.multimediaUrl
                ? item?.id
                : "";
            notStarted =
              !notStarted &&
              item?.isFree &&
              item?.watchStatus === "Not Started" &&
              item?.multimediaUrl
                ? item?.id
                : "";
            return item?.isFree && item?.multimediaUrl;
          });

        if (inProgress) {
          return history.push(`/play-course/${courseDetail?.id}/${inProgress}`);
        } else if (notStarted) {
          return history.push(`/play-course/${courseDetail?.id}/${notStarted}`);
        } else if (freeEpisodes?.length > 0) {
          return history.push(
            `/play-course/${courseDetail?.id}/${freeEpisodes[0].id}`
          );
        } else if (
          courseDetail?.episodes?.length > 0 &&
          freeEpisodes?.length === 0
        ) {
          return setOpenPaymentDialog(true);
        }
      } else if (courseDetail?.isPaid) {
        const inProgressEpisode =
          courseDetail?.episodes?.length > 0 &&
          courseDetail?.episodes?.find(
            (item) => item?.watchStatus === "Started" && item?.multimediaUrl
          );

        const notStarted =
          courseDetail?.episodes?.length > 0 &&
          courseDetail?.episodes?.find(
            (item) => item?.watchStatus === "Not Started" && item?.multimediaUrl
          );

        const multimediaEpisode =
          courseDetail?.episodes?.length > 0 &&
          courseDetail?.episodes?.find((item) => item?.multimediaUrl);

        if (inProgressEpisode) {
          return history.push(
            `/play-course/${courseDetail?.id}/${inProgressEpisode?.id}`
          );
        } else if (notStarted) {
          return history.push(
            `/play-course/${courseDetail?.id}/${notStarted?.id}`
          );
        } else if (multimediaEpisode) {
          return history.push(
            `/play-course/${courseDetail?.id}/${multimediaEpisode?.id}`
          );
        }
      }
    } else {
      setNoEpisode(true);
    }
  };

  const handleStatusChange = () => {
    dispatch(
      courseActiveInActive({
        token: authReducerState?.token,
        courseId: courseDetail?.id,
        status: courseStatus ? "InActive" : "Active",
      })
    );
    return;
  };

  const courseViewHeroImageCard = (
    <div
      className={classes.root}
      style={{
        backgroundImage: `url(${courseDetail?.image}),linear-gradient(360deg, rgba(21, 57, 89, 0.8) 0%, rgba(123, 146, 166, 0.8) 100%)`,
        backgroundPosition: "center center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        height: 0,
        paddingTop: "56.25%",
        position: "relative",
      }}
    >
      <div
        className={classes.root}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <Grid container style={{ height: "100%" }}>
          <Grid container justify="flex-end" spacing={1}>
            <Grid item xs={2} style={{ height: "25px" }}>
              {authReducerState?.user?.id === courseDetail?.userId ? (
                courseDetail?.plan && (
                  <Button
                    style={{
                      background: `linear-gradient(360deg, #4caf50 100%, ${hexToRGB(
                        courseDetail?.category?.color2,
                        1
                      )} 0%)`,
                      color: "#fff",
                      cursor: "default",
                      marginTop: theme.spacing(1),
                    }}
                  >
                    {courseDetail?.plan?.name}
                  </Button>
                )
              ) : (
                <Grid container justify="flex-end">
                  {courseDetail?.isUserFan ? (
                    <img
                      src={heartIconSelected}
                      onClick={handleCourseFavorite}
                      className={classes.heartIconStyle}
                    />
                  ) : (
                    <img
                      src={heartIcon}
                      onClick={handleCourseFavorite}
                      className={classes.heartIconStyle}
                    />
                  )}{" "}
                  {/* <Favorite
                      onClick={handleCourseFavorite}
                      style={{
                        paddingTop: "15px",
                        filter: "drop-shadow(0 0 0.1 rem black)",
                        color:
                          courseDetail?.isUserFan === true ? " #09A9BB" : "#FFF",
                        cursor: courseDetail?.id && "pointer",
                        marginRight: "15px"
                      }}
                    /> */}
                </Grid>
              )}
            </Grid>

            {videoUrl && (
              <Grid
                item
                xs={12}
                container
                style={{
                  justifyContent: "center",
                  display: "flex",
                  textAlign: "center",
                }}
              >
                <PlayCircleOutline
                  variant="contained"
                  color="default"
                  className={classes.button}
                  onClick={() => setPreview(true)}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );

  const handleEnded = () => {
    if (!introVideo) {
      setPreview(false);
    }
    setIntroVideo(!introVideo);
  };

  const handleCloseShare = () => {
    setShareOpen(false);
  };
  const handleErrorsModelClose = () => {
    setMessages("");
    setErrors(false);
  };
  return (
    <>
      <MetaTags>
        <meta property="og:title" content={courseDetail?.name} />
        <meta property="og:description" content={courseDetail?.remarks} />
        <meta
          property="og:image"
          content={`${window.location.origin}/${courseDetail?.image}`}
        />
        <meta property="og:url" content={`${window.location.href}`} />
        <meta property="og:type" content={`app`} />
        <meta property="og:site_name" content="omidlife.com" />
        <meta
          property="twitter:image"
          alt="image"
          content={`${window.location.origin}/${courseDetail?.image}`}
        />
        <meta name="twitter:card" content={courseDetail?.remarks}></meta>
      </MetaTags>
      <ExpireSubscriptionPopup
        subscriptionExpire={subscriptionExpire}
        setPopUp={() => setSubscriptionExpire(false)}
      />
      <RecommendUpgrade
        recommend={recommendUpgrade}
        setRecommendUpgrade={() => setRecommendUpgrade(false)}
      />

      {shareOpen && (
        <Dialog open={shareOpen} onClose={handleCloseShare}>
          <DialogTitle>
            <Grid container justify="space-between">
              <Grid item>
                <Typography component="h4" variant="h5">
                  {shareOpen}
                </Typography>
              </Grid>
              <Grid item>
                <Button onClick={handleCloseShare}>
                  <Close />
                </Button>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <InviteAndShare
              course={courseDetail}
              buttonText="close"
              invite={shareOpen === "Invite"}
              share={shareOpen === "Share"}
              handleNext={() => setShareOpen(false)}
            />
          </DialogContent>
        </Dialog>
      )}
      <Modal
        disableEnforceFocus={true}
        open={openModel}
        onClose={handleCloseModal}
      >
        {modalBody}
      </Modal>
      <Modal
        disableEnforceFocus={true}
        open={noEpisode}
        onClose={() => setNoEpisode(false)}
      >
        {noEpisodeBody}
      </Modal>
      <Grid container>
        <Grid item xs={12} container style={{ padding: "0px 0px 0px 50px" }}>
          <Grid
            item
            xs={7}
            container
            className={classes.scroll}
            style={{ padding: "0px 20px 0px 0px" }}
          >
            <Grid
              item
              xs={12}
              style={{
                marginLeft: "-20px",
                display: "flex",
                alignItems: "center",
                height: "87px",
              }}
            >
              {/*   <TopBar
                appBarConfig={{ show: true, text: "Course" }}
                style={{
                  color: "#068594",
                  backgroundColor: "#FCFCFC",
                  float: "left",
                }}
              /> */}

              <AppBar
                className={classes.appBar}
                style={{ backgroundColor: "#FCFCFC" }}
              >
                <IconButton
                  onClick={() => history.push("/dashboard")}
                  disableFocusRipple
                  className={classes.backButton}
                  //  style={{ color: props.appBarConfig?.arrowColor }}
                >
                  <ArrowBack />
                </IconButton>
                <Toolbar>
                  <Typography
                    className={classes.appBarText}
                    variant="h6"
                    style={{ fontSize: isDesktop ? "20px" : "18px" }}
                  >
                    Course
                  </Typography>
                </Toolbar>
              </AppBar>
            </Grid>

            <Grid item xs={12}>
              {!preview ? (
                courseViewHeroImageCard
              ) : (
                <div className={classes.playerWrapper}>
                  <div
                    className={clsx(classes.reactPlayer, classes.preLoadBody)}
                  >
                    <CircularProgress style={{ color: "#09A9BB" }} />
                  </div>
                  <ReactPlayer
                    controls="true"
                    height="100%"
                    width="100%"
                    className={classes.reactPlayer}
                    onEnded={handleEnded}
                    playing={true}
                    onError={() => {
                      videoUrl && setPreview(true);
                    }}
                    // url={introVideo ? IntroVideo : courseDetail?.previewUrl}
                    url={videoUrl}
                    config={{
                      youtube: {
                        playerVars: { showinfo: 1 },
                      },
                      file: { attributes: { controlsList: "nodownload" } },
                    }}
                  />
                </div>
              )}
            </Grid>

            <Grid
              container
              direction="row"
              style={{ margin: "0px 0px 0px 0px" }}
            >
              <Grid item xs={12}>
                <Typography className={classes.courseTitle}>
                  {courseDetail?.name}
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                container
                alignItem="flex-end"
                style={{ display: "flex", height: "60px", width: "auto" }}
              >
                <Grid
                  item
                  xs={3}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "5px",
                  }}
                >
                  {courseDetail?.user?.id === authReducerState?.user?.id ? (
                    <Button
                      className={classes.startNowBtn}
                      style={{ background: "#39A746" }}
                      onClick={() =>
                        history.push(`/edit-course/${courseDetail.slug}`)
                      }
                    >
                      <Edit /> {intl.formatMessage({ id: "edit" })}
                    </Button>
                  ) : courseDetail?.isUserTaking ? (
                    <Button
                      className={classes.startNowBtn}
                      style={{ background: "#39A746" }}
                      onClick={startEpisode}
                    >
                      <Typography>
                        {intl.formatMessage({ id: "startNow" })}
                      </Typography>
                    </Button>
                  ) : (
                    <Button
                      className={classes.startNowBtn}
                      style={{ background: "#39A746" }}
                      disabled={!courseDetail?.id}
                      onClick={() => {
                        if (
                          courseDetail?.plan?.name === "Donation" ||
                          courseDetail?.plan?.name === "Free" ||
                          courseDetail?.plan?.name === "Freemium" ||
                          courseDetail?.plan?.name === "Subscription" ||
                          courseDetail?.plan?.name === "Paid"
                        ) {
                          /* {
                          setOpenModel(false);
                          setJoinDialog(false);
                        } else  */ joinCourseHandler();
                        }
                      }}
                    >
                      <Typography>
                        {courseDetail?.plan?.name === "Donation" ||
                        courseDetail?.plan?.name === "Free" ? (
                          "Join for free"
                        ) : courseDetail?.plan?.name === "Subscription" ? (
                          `Subscribe`
                        ) : (
                          <>
                            {`Join Now For `}
                            {reducerUser?.purchasedSubscription?.length ===
                            0 ? (
                              `${configurations?.currency}${
                                courseDetail?.price
                                  ? courseDetail?.price
                                  : courseDetail?.plan?.price
                                  ? courseDetail?.plan?.price
                                  : 0
                              }`
                            ) : (reducerUser?.purchasedSubscription[0]
                                ?.interval === "monthly" &&
                                reducerUser?.purchasedSubscription[0]
                                  ?.SubscriptionPlan?.omidDiscount > 0) ||
                              (reducerUser?.purchasedSubscription[0]
                                ?.interval === "annual" &&
                                reducerUser?.purchasedSubscription[0]
                                  ?.SubscriptionPlan?.annualOmidDiscount >
                                  0) ? (
                              <strike>
                                {`${configurations?.currency}${
                                  courseDetail?.price
                                    ? courseDetail?.price
                                    : courseDetail?.plan?.price
                                    ? courseDetail?.plan?.price
                                    : 0
                                }`}
                              </strike>
                            ) : (
                              <></>
                            )}
                            {reducerUser?.purchasedSubscription?.length > 0 && (
                              <span style={{ paddingLeft: "2px" }}>
                                {`(${configurations?.currency}${getPrice(
                                  courseDetail?.price
                                    ? courseDetail?.price
                                    : courseDetail?.plan?.price
                                    ? courseDetail?.plan?.price
                                    : 0,
                                  reducerUser?.purchasedSubscription
                                )})`}
                              </span>
                            )}
                          </>
                        )}
                      </Typography>
                    </Button>
                  )}
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {!courseDetail?.isPrivate && (
                    <Button
                      className={classes.startNowBtn}
                      style={{
                        background:
                          "linear-gradient(360deg,  #C54DC2 -13.24%, #C54DC2 100%)",
                      }}
                      onClick={handleCreateChallenge}
                      disabled={
                        courseDetail?.status !== "Active" || !courseDetail?.id
                          ? true
                          : false
                      }
                    >
                      <Typography>
                        {intl.formatMessage({ id: "createAChallenge" })}
                      </Typography>
                    </Button>
                  )}
                </Grid>

                {/* {courseDetail?.plan?.name === "Subscription" && (<Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                 <Button
                    className={classes.subscribeBtn}
                    // style={{color: "blue", fontWeight : '700'}}
                    disabled={!courseDetail?.id}
                    onClick={() => history.push("/more/subscription-plan")}
                  >
                    <Typography>
                      {reducerUser?.purchasedSubscription?.SubscriptionPlan
                        ?.name === "free" ||
                      reducerUser?.purchasedSubscription?.length === 0
                        ? "SUBSCRIBE"
                        : "UPGRADE"}
                    </Typography>
                  </Button>
                </Grid>)} */}

                <Grid
                  item
                  xs={2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {authReducerState?.user?.id === courseDetail?.userId
                    ? (courseDetail?.status === "Active" ||
                        courseDetail?.status === "InActive") && (
                        <FormControlLabel
                          style={{ marginRight: "0px" }}
                          control={
                            <CustomSwitch
                              onChange={handleStatusChange}
                              checked={courseStatus}
                            />
                          }
                          label={
                            <span className={classes.startTimeTxt}>
                              {courseDetail?.status}
                            </span>
                          }
                        />
                      )
                    : /* ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */
                      authReducerState?.user?.id !== courseDetail?.userId &&
                      courseDetail?.isUserTaking &&
                      (courseDetail?.plan?.name === "Donation" ||
                        (courseDetail?.plan?.name === "Freemium" &&
                          !courseDetail?.isPaid)) && (
                        <Tooltip
                          title={
                            courseDetail?.plan?.name === "Freemium"
                              ? `Pay $${
                                  courseDetail?.price
                                    ? courseDetail?.price
                                    : courseDetail?.plan?.price
                                    ? courseDetail?.plan?.price
                                    : 0
                                } to access full course`
                              : "Donate to appreciate course creator"
                          }
                        >
                          <Button
                            onClick={() => setOpenPaymentDialog(true)}
                            className={classes.startNowBtn}
                            style={{
                              background: `linear-gradient(360deg, #4caf50 100%, ${hexToRGB(
                                courseDetail?.category?.color2,
                                1
                              )} 0%)`,
                            }}
                          >
                            {courseDetail?.plan?.name === "Donation"
                              ? intl.formatMessage({ id: "donation" })
                              : `${intl.formatMessage({ id: "pay" })} ${
                                  configurations?.currency
                                }
                          ${
                            courseDetail?.price
                              ? courseDetail?.price
                              : courseDetail?.plan?.price
                              ? courseDetail?.plan?.price
                              : 0
                          }`}
                          </Button>
                        </Tooltip>
                      )}
                </Grid>

                <Grid
                  item
                  xs={3}
                  container
                  justify="center"
                  style={{ display: "flex" }}
                >
                  <Grid item>
                    {((courseDetail?.isPrivate &&
                      courseDetail?.user?.id === authReducerState?.user?.id) ||
                      !courseDetail?.isPrivate) && (
                      <Tooltip
                        title={
                          <span
                            style={{ fontSize: "14px", letterSpacing: 0.3 }}
                          >
                            Invite
                          </span>
                        }
                        placement="top"
                      >
                        <IconButton
                          onClick={() => setShareOpen("Invite")}
                          className={classes.inviteAndShare}
                          disabled={
                            !courseDetail ||
                            courseDetail?.status === "Incomplete" ||
                            courseDetail?.status === "Blocked" ||
                            courseDetail?.status === "InActive"
                          }
                        >
                          <InsertInvitation />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                  {!courseDetail?.isPrivate && (
                    <Grid item>
                      <Tooltip
                        title={
                          <span
                            style={{ fontSize: "14px", letterSpacing: 0.3 }}
                          >
                            Share
                          </span>
                        }
                        placement="top"
                      >
                        <IconButton
                          disabled={
                            !courseDetail ||
                            courseDetail?.status === "Incomplete" ||
                            courseDetail?.status === "Blocked" ||
                            courseDetail?.status === "InActive"
                          }
                          onClick={() => setShareOpen("Share")}
                          className={classes.inviteAndShare}
                          style={{ marginLeft: "10px" }}
                        >
                          <Share />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {courseDetail?.user?.id === authReducerState?.user?.id ? (
              <Grid item xs={12} container>
                <Grid item xs={12}>
                  <AppBar position="static" className={classes.TabAppBar}>
                    <StyledTabs
                      value={value}
                      onChange={handleChange}
                      aria-label="styled tabs example"
                      classes={{
                        root: classes.tabLabel,
                        selected: classes.tabSelected,
                      }}
                    >
                      <StyledTab
                        label={intl.formatMessage({ id: "episodes" })}
                        classes={{
                          root: classes.tabLabel,
                          selected: classes.tabSelected,
                        }}
                      />
                      <StyledTab
                        label={intl.formatMessage({ id: "stats" })}
                        classes={{
                          root: classes.tabLabel,
                          selected: classes.tabSelected,
                        }}
                      />
                    </StyledTabs>
                  </AppBar>
                </Grid>
                <Grid item xs={12}>
                  <TabPanel
                    value={value}
                    index={0}
                    style={{ width: "100%", minHeight: "100vh" }}
                  >
                    <Grid container style={{ paddingTop: "20px" }}>
                      <CourseViewEpisodesCard
                        setOpen={
                          courseDetail?.isUserTaking
                            ? setOpenPaymentDialog
                            : () => {
                                setOpenModel(true);
                                setModalType("joinCourse");
                              }
                        }
                        category={courseDetail?.category}
                        episodes={courseDetail?.episodes}
                      />
                    </Grid>
                  </TabPanel>
                  <TabPanel
                    value={value}
                    index={1}
                    style={{ width: "100%", minHeight: "100vh" }}
                  >
                    <CourseStats />
                  </TabPanel>
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid item xs={12}>
                  <CourseViewEpisodesCard
                    setOpen={
                      courseDetail?.isUserTaking
                        ? setOpenPaymentDialog
                        : () => {
                            setOpenModel(true);
                            setModalType("joinCourse");
                          }
                    }
                    category={courseDetail?.category}
                    episodes={courseDetail?.episodes}
                  />
                </Grid>
              </>
            )}
          </Grid>

          <Grid
            item
            xs={5}
            container
            justifyContent="center"
            style={{ padding: "0px 15px 0px 15px", background: "#E8EEEF" }}
            className={classes.scroll}
          >
            <Grid
              item
              xs={12}
              style={{ marginTop: "87px" }}
              justifyContent="center"
            >
              <CourseInstructorIntroCard
                instructor={courseDetail?.user}
                tags={courseDetail?.tags}
                category={courseDetail?.category}
                remarks={courseDetail?.remarks}
                status={courseDetail?.status}
              />
            </Grid>

            <Grid
              item
              xs={12}
              style={{ marginTop: 15 }}
              justifyContent="center"
            >
              <CoursePlanCard />
            </Grid>

            <Grid item xs={12}>
              <Typography
                style={{
                  fontFamily: "Reef",
                  fontStyle: "normal",
                  fontWeight: 900,
                  fontSize: "22px",
                  paddingTop: "20px",
                  color: "#1B1B1B",
                  textAlign: "center",
                }}
              >
                {intl.formatMessage({ id: "reviews" })}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <CourseReviews courseReviews={courseDetail?.reviews_ratings} />
            </Grid>

            <Grid item xs={12}>
              {(courseDetail?.isUserTaking ||
                courseDetail?.user?.id === authReducerState?.user?.id) && (
                <PostReviewCard courseId={courseDetail?.id} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CustomerCardList
        open={openPaymentDialog}
        setOpen={setOpenPaymentDialog}
        setRecommendUpgrade={setRecommendUpgrade}
        setShareOpen={setShareOpen}
        courseId={courseDetail?.id}
        plan={courseDetail?.plan}
        price={getPrice(
          courseDetail?.price,
          reducerUser?.purchasedSubscription,
          "course"
        )}
      />
      {/*  <Dialog open={joinDialog} onClose={() => setJoinDialog(false)}>
        <DialogContent>
          {courseDetail?.plan?.name === "Freemium"
            ? "Are you sure you want to join Freemium course? This course is partially free. You will be able to see some of episodes for free. And you should pay for locked episode before play"
            : courseDetail?.plan?.name === "Subscription"
            ? "Are you sure you want to join under subscription course?"
            : "Are you sure you want to join free course?"}
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.proceedButton}
            onClick={() => {
              setJoinDialog(false);
              joinCourseHandler();
            }}
          >
            Yes
          </Button>
          <Button onClick={() => setJoinDialog(false)}>No</Button>
        </DialogActions>
      </Dialog>
 */}
      <Dialog open={subscribeDialog} onClose={() => setSubscribeDialog(false)}>
        <DialogContent>
          "You currently do not have any active subscription. Please upgrade and
          give yourself the best present."
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.subscribeButton}
            onClick={() => {
              setSubscribeDialog(false);
              history.push("/more/subscription-plan");
            }}
          >
            Subscribe
          </Button>
          <Button onClick={() => setSubscribeDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/*
      <Dialog  open={errors} onClose={handleErrorsModelClose}>
        <Grid>
          <Grid xs={12} className={classes.messageBody}>
          <DialogContent>
          <Grid container direction="row">
            <Error size="small"  className={classes.error}/>
              <Typography>
              {messages}
                </Typography>
              </Grid>

        </DialogContent>
          </Grid>

          <Grid xs={12}>
          <DialogActions>
           <Button
            className={classes.subscribeButton}
            onClick={() => {
              setSubscribeDialog(false);
              history.push("/more/subscription-plan");
            }}
          >
            Subscribe
          </Button>
          <Button onClick={handleErrorsModelClose}>Close</Button>
        </DialogActions>
          </Grid>


        </Grid>

      </Dialog> */}

      <Dialog
        open={errors}
        onClose={handleErrorsModelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/*  <DialogTitle id="alert-dialog-title">
        <Error  className={classes.error}/>
          <span style={{fontSize:"25px"}}> Attention</span>
        </DialogTitle> */}
        <DialogContent>
          <Grid container>
            <Grid lg={1}>
              <Error className={classes.error} />
            </Grid>
            <Grid style={{ marginTop: "15px" }} lg={11}>
              {" "}
              <span>{messages}</span>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.subscribeButton}
            onClick={() => {
              setSubscribeDialog(false);
              history.push("/more/subscription-plan");
            }}
          >
            Subscribe
          </Button>
          <Button onClick={handleErrorsModelClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ViewCourseDesktop;

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: "#fff",
    color: "#000",
    flexDirection: "row",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    right: "unset",
    position: "relative",
    marginLeft: "25px",
    boxShadow: "none",
  },
  error: {
    color: "#F90606",
    paddingTop: "15px",
    paddingRight: "15px",
  },
  messageBody: {
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
    textAlign: "center",
  },
  appBarMobile: {
    background: "#FFFFFF",
    color: "#000",
    flexDirection: "row",
    padding: theme.spacing(1.5),
    justifyContent: "space-between",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    right: "unset",
    position: "sticky",
  },
  appBarText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "20px",
    lineHeight: "23px",
  },
  appBarTextMobile: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "26px",
    lineHeight: "35px",
  },
  backButton: {
    background: "transparent",
    color: "#000",
    padding: "0px",
    "&:hover": {
      background: "transparent",
    },
  },
  TabAppBar: {
    backgroundColor: "#FFF",
    width: "100%",
    boxShadow: "none",
    display: "flex",
    justifyContent: "space-between",
  },
  tabLabel: {
    color: "#666",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    minWidth: "20%",
    textTransform: "none",
    textAlign: "left",
  },
  tabSelected: {
    color: "#47b8c4",
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
  },
  root: {
    width: "100%",
    borderRadius: "10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  media: {
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(360deg, rgba(21, 57, 89, 0.8) 0%, rgba(123, 146, 166, 0.8) 100%)",
    borderRadius: "11px",
    transform: "rotate(360deg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  courseTitle: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "20px",
    color: "#000",
    paddingBottom: "15px",
  },
  courseDescription: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "20px",
    lineHeight: "27px",
    letterSpacing: "0.015em",
    color: "#FFFFFF",
    padding: "20px 0px",
  },
  proceedButton: {
    background: "#fbae02",
    color: "#fff",
    "&:hover": {
      background: "#fbae02",
      color: "#fff",
    },
    "&:focus": {
      background: "#fbae02",
      color: "#fff",
    },
  },
  subscribeButton: {
    background: "#088A2D",
    color: "#fff",
    "&:hover": {
      background: "#088A2D",
      color: "#fff",
    },
    "&:focus": {
      background: "#088A2D",
      color: "#fff",
    },
  },

  startNowBtn: {
    height: "2.463em",
    width: "100%",
    background: "linear-gradient(360deg, #15AEBF 0%, #A6D6FF 100%)",
    borderRadius: "10px",
    transform: "rotate(360deg)",
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "10px",
    color: "#FFFFFF",
  },
  subscribeBtn: {
    height: "2.463em",
    width: "95%",
    background: "linear-gradient(360deg, #09A9BB 0%, #A6D6FF 100%)",
    borderRadius: "10px",
    transform: "rotate(360deg)",
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "10px",
    color: "#FFFFFF",
  },
  CourseImageCardText: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "16px",
    lineHeight: "10px",
    color: " #FFFFFF",
  },
  instructorIntroText: {
    fontFamily: theme.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
  },
  instructorIntroTextTitle: {
    fontSize: "20px",
    lineHeight: "27px",
    color: "#1B1B1B",
  },
  instructorIntroTextSubTitle: {
    fontSize: "16px",
    lineHeight: "22px",
    color: "#B0B0B0",
    letterSpacing: "0.015em",
    padding: "10px 0",
  },
  courseTags: {
    background:
      "linear-gradient(180deg, rgba(123, 146, 166, 0.95) 0%, rgba(21, 57, 89, 0.95) 100%)",
    borderRadius: "60px",
    transform: "rotate(3600deg)",
    marginRight: "3px",
  },
  episodeSubContainers: {
    marginTop: "-30px",
  },
  episodesCardContainer: {
    width: "100%",
    minHeight: "19.750em",
    borderRadius: "10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  paper: {
    position: "absolute",
    height: 330,
    maxWidth: 315,
    minWidth: 300,
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    outline: "none",
  },

  modelBodyText: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "17px",
    lineHeight: "27px",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: "#192247",
  },
  modelBtn: {
    height: "73px",
    width: "100%",
    fontFamily: theme.fontFamily.Roboto,
    cursor: "pointer",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",
    color: theme.colors.white,
  },
  inviteAndShare: {
    background: "linear-gradient(360deg, #15AEBF 0%, #A6D6FF 100%)",
    fontSize: "20px",
    lineHeight: "10px",
    color: "#FFFFFF",
  },
  modalBtnGrid: {
    padding: "0px",
    alignSelf: "flex-end",
    color: "#fff",
    cursor: "pointer",
    background: `linear-gradient(#09A9BB 1%,#09A9BB 100%)`,
    height: "73px",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  startTimeTxt: {
    fontFamily: theme.fontFamily.Roboto,
    fontStyle: "normal",
    fontWeight: 500,

    fontSize: "20px",
    lineHeight: "10px",
    color: "#15AEBF",
  },
  msgBody: {
    minHeight: "200px",
    alignItems: "center",
    display: "flex",
  },
  readMore: {
    fontSize: "12px",
    cursor: "pointer",
    color: "#C4C4C4",
    "&:hover": {
      textDecoration: "underline",
      color: "#84CDF0",
    },
  },
  scroll: {
    height: "100vh",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent",
    },
  },
  heartIconStyle: {
    paddingTop: "15px",
    cursor: "pointer",
    marginRight: "15px",
  },
  button: {
    textTransform: "none",
    color: "#09B1D3",
    cursor: "pointer",
    fontSize: "70px",
  },
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%",
    height: 0,
  },
  reactPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
  },

  ratio: {
    position: "relative",
    width: "100%",
    height: 0,

    paddingTop: `56.25%`,
  },
  preLoadBody: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#a9a9a9",
    width: "100%",
  },
  closeIcon: {
    color: "#a9a9a9",
    float: "right",
    cursor: "pointer",
  },
}));
